import { defineStore, acceptHMRUpdate } from 'pinia';
import * as backend from '@/services/backend';
import { addHours } from 'date-fns';
import { SubscriptionType } from '@/model';

export type LicenseOption = {
  type: SubscriptionType;
  team?: { name: string; id: number };
};

type By = 'device' | 'isp' | 'adapter' | 'server';

type State = {
  __tunnel: backend.TunnelStats | null;
  __net: backend.NetStats | null;
  __labels: Date[],
  license: LicenseOption,
  timeframe: [Date, Date];
  grouped: Partial<Record<By, { [k: string]: number[]}>> | null;
  by: By;
  type: 'bar' | 'cumulative',
};

const lastMidnight = new Date(new Date().setHours(0, 0, 0, 0)); // local timezone
const twoWeeksAgo = addHours(new Date(), -24 * 14);

export const useChartStore = defineStore('chart', {
  state: (): State => ({
    __tunnel: null,
    __net: null,
    __labels: [],
    license: { type: 'individual' },
    timeframe: [twoWeeksAgo, lastMidnight],
    grouped: null,
    by: 'device',
    type: 'bar',
  }),
  getters: {
    table: state => {
      return state.grouped && Object.entries(state.grouped[state.by]!)
        .map(([by, arr]) => ({
          field: by,
          total: arr.reduce((acc, curr) => acc + curr, 0),
          // the compiler is not aggresively type-inferring
        } as { field: By, total: number }))
        .toSorted((a, b) => b.total - a.total);
    },
    basicStats: state => {
      const verb = ['device', 'user', 'server'].includes(state.by) ? 'tunnel' : 'net';

      const stats = verb === 'tunnel' ? state.__tunnel : state.__net;
      if (!stats) return undefined;

      return {
        traffic: stats.objects.map(o => o.traffic).reduce((a, b) => a + b, 0),
        devices: new Set(stats.objects.map(o => o.device)).size,
        users: new Set(stats.objects.map(o => o.user)).size,
        duration: stats.objects.map(o => o.duration).reduce((a, b) => a + b, 0),
        sessions: new Set(stats.objects.map(o => o.session)).size,
        servers: new Set(stats.objects.map(o => o.server)).size,
      };
    },
  },
  actions: {
    async fetch() {
      const [from, to] = this.$state.timeframe;

      const verb = ['device', 'user', 'server'].includes(this.$state.by) ? 'tunnel' : 'net';

      if (verb === 'tunnel') {
        if (this.$state.license.type === 'individual') {
          this.$state.__tunnel = await backend.individualTunnelStats({ from, to });
        } else if (this.$state.license.type === 'teams') {
          this.$state.__tunnel = await backend.teamsTunnelStats(this.$state.license.team!.id, { from, to });
        } else if (this.$state.license.type === 'dedicated_server') {
          this.$state.__tunnel = await backend.dedicatedServerTunnelStats({ from, to });
        }

        if (!this.$state.__tunnel?.objects.length) {
          return;
        }

        const objs = this.$state.__tunnel.objects;
        const devices = Array.from(new Set(objs.map(o => o.device)));
        const servers = Array.from(new Set(objs.map(o => o.server)));

        const deviceMap = Object.fromEntries(devices.map(dev => [dev, new Array(objs.length).fill(null)] as [string, number[]]));
        const serverMap = Object.fromEntries(servers.map(dev => [dev, new Array(objs.length).fill(null)] as [string, number[]]));
        objs.forEach((o, idx) => (deviceMap[o.device]![idx] = o.traffic));
        objs.forEach((o, idx) => serverMap[o.server]![idx] = o.traffic);
        // {
        //   labels: ['dev1', 'dev2'],
        //   datasets: [ { dev1: [null, 1], dev2: [2, null] } ],
        // }
        this.$state.__labels = objs.map(o => o.datetime);
        return this.$state.grouped = { device: deviceMap, server: serverMap };
      }

      if (verb === 'net') {
        if (this.$state.license.type === 'individual') {
          this.$state.__net = await backend.individualNetStats({ from, to });
        } else if (this.$state.license.type === 'teams') {
          this.$state.__net = await backend.teamsNetStats(this.$state.license.team!.id, { from, to });
        }

        if (!this.$state.__net?.objects.length) {
          return;
        }

        const objs = this.$state.__net.objects;
        const isps = Array.from(new Set(this.$state.__net.objects.map(x => x.isp)));
        const adapters = Array.from(new Set(this.$state.__net.objects.map(x => x.adapter)));

        const ispMap = Object.fromEntries(isps.map(x => [x, new Array(objs.length).fill(null)] as [string, number[]]));
        const adapterMap = Object.fromEntries(adapters.map(x => [x, new Array(objs.length).fill(null)] as [string, number[]]));
        objs.forEach((o, idx) => ispMap[o.isp]![idx] = o.traffic);
        objs.forEach((o, idx) => adapterMap[o.adapter]![idx] = o.traffic);

        this.$state.__labels = objs.map(o => o.datetime);
        return this.$state.grouped = { isp: ispMap, adapter: adapterMap };
      }
    },
  },
});

import.meta.hot?.accept(acceptHMRUpdate(useChartStore, import.meta.hot));
