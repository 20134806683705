<template>
  <PageNavbar />
  <AuthGuard>
    <div id="content" class="container">

      <div style="display: flex"><h1>Reports</h1><img class="ms-1" src="/images/BETA_TAG.svg" /></div>
      <Card class="mb-2" style="align-items: flex-start">
        <template #content>
          <div style="display: flex; flex-direction: column;">
            <span>Filter By:</span>
            <Select
              v-model="license"
              variant="filled"
              :options="filterByOpts"
              :optionLabel="licenseNameOf"
              pt:root:style="border: none; background-color: var(--p-card-background)"
              pt:label:style="font-weight: 700; font-size: 24px; color: #00adee; background-color: none; padding-left: 0"
              pt:dropdown:style="color: #00adee;"
            ></Select>
            <small v-if="store.license?.type === 'individual'">{{ userStore.email }}</small>
          </div>
        </template>
      </Card>

      <Card>
        <template #content>
          <div style="display: flex; justify-content: space-between;">
            <div style="display: flex; flex-direction: row">
              <h3>Report:</h3>
              <Select
                v-model="report"
                variant="filled"
                :options="['Total Usage']"
                pt:root:class="py-0 pr-0 ps-2"
                pt:root:style="border: none; background-color: var(--p-card-background);"
                pt:label:style="font-weight: 700; font-size: 24px; color: #00adee; background-color: none; padding: 0"
                pt:dropdown:style="color: #00adee"
              ></Select>
            </div>
          </div>

          <div v-if="store.basicStats" class="mb-2" style="display: flex; flex-direction: row">
            <Panel header="Users"          style="flex: 1 1 0">{{store.basicStats.users}}</Panel>
            <Panel header="Devices"        style="flex: 1 1 0">{{store.basicStats.devices}}</Panel>
            <Panel header="Data Used"      style="flex: 1 1 0">{{humanFileSize(store.basicStats.traffic)}}</Panel>
            <Panel header="Servers"        style="flex: 1 1 0">{{store.basicStats.servers}}</Panel>
            <Panel header="Connected Time" style="flex: 1 1 0">{{formatDurationSeconds(store.basicStats.duration)}}</Panel>
            <Panel header="VPN Sessions"   style="flex: 1 1 0">{{store.basicStats.sessions}}</Panel>
          </div>

          <small>Total data transferred through Speedify (includes upload and download)</small>
          <!-- TODO: timezone -->
          <ECharts
            v-if="['individual', 'teams', 'dedicated_server'].includes(store.license?.type) && store.grouped"
            style="height: 400px"
            :option="option"
          ></ECharts>
          <Skeleton v-else shape="rectangle" size="10rem"></Skeleton>

          <div style="display: flex; flex-direction: row; align-items: center">
            <h3 class="mb-0">Break Down By:</h3> <!-- TODO: server, user -->
            <Select
              v-model="by"
              variant="filled"
              :options="
                store.license.type === 'dedicated_server'
                  ? breakdownOpts.filter(x => ['device', 'server'].includes(x.code))
                  : breakdownOpts.filter(x => ['device', 'isp', 'adapter'].includes(x.code))
              "
              optionLabel="name"
              pt:root:style="border: none; background-color: var(--p-card-background);"
              pt:label:style="font-weight: 700; font-size: 24px; color: #00adee; background-color: none;"
              pt:dropdown:style="color: #00adee"
              pt:label:class="py-0 ps-2 pe-0 fs-3"
            ></Select>
          </div>

          <!-- DataTable is impure: https://github.com/primefaces/primevue/issues/5261 -->
          <DataTable v-if="store.table && ['individual', 'teams', 'dedicated_server'].includes(store.license?.type)" :value="store.table">
            <Column
              :field="normalizeTableName"
              :header="by.name"
              sortable
              :pt="{ headerCell: ({}) => ({ style: 'background-color: #F9FAFB' }) }"
            ></Column>
            <!-- latency, package loss, max dn speed, connect,disconnect(not yet), cumulative, bar -->
            <Column
              field="total"
              header="Total Usage"
              sortable
              :pt="{ headerCell: ({}) => ({ style: 'background-color: #F9FAFB' }) }"
            >
              <template #body="slotProps">{{ humanFileSize(slotProps.data.total) }}</template>
            </Column>
          </DataTable>
        </template>
      </Card>

    </div>
  </AuthGuard>
  <PageFooter />
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from 'vue';
import { storeToRefs } from 'pinia';

import * as echarts from "echarts/core";
import type { EChartsOption } from 'echarts';
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, PieChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DataZoomComponent,
} from "echarts/components";
import ECharts  from "vue-echarts";
import { format, intervalToDuration, formatDuration } from 'date-fns';

import Skeleton from 'primevue/skeleton';
import Card from 'primevue/card';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Select from 'primevue/select';
import Panel from 'primevue/panel';

import AuthGuard from '@/components/AuthGuard.vue';
import PageFooter from '@/components/PageFooter.vue';
import PageNavbar from '@/components/PageNavbar.vue';

import { useUserStore } from '@/store/user';
import { useChartStore, LicenseOption } from '@/store/chart';
import { useLinkedDevicesStore } from '@/store/linkedDevices';
import { humanFileSize, expose } from '@/util';
import { isOwnerOrAdmin } from '@/services/subscription';

const userStore = useUserStore();
const store = useChartStore();
const linkedDevicesStore = useLinkedDevicesStore();
const { subscriptions } = storeToRefs(userStore);

echarts.use([
  CanvasRenderer,
  LineChart,
  PieChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DataZoomComponent,
]);

const normalizeLegendName = (name: string) => {
  if (store.by === 'device') return deviceNameOf(name);
  if (store.by === 'isp') return name || 'UNKNOWN ISP'; // TODO: style __UNKNOWN__
  if (store.by === 'adapter') {
    if (name === 'NetworkShare Client') return 'Pair & Share Client';
    if (name === 'NetworkShare Host') return 'Pair & Share Host';
  }

  return name;
};

const option = computed<EChartsOption>(() => ({
  tooltip: { trigger: 'axis', valueFormatter: val => Number.isSafeInteger(val) ? humanFileSize(val as number) : '' },
  legend: {},
  xAxis: { type: 'category', data: store.__tunnel?.objects.map(o => format(o.datetime, 'yyyy-MM-dd HH:mm:ss')) },
  yAxis: { type: 'value', axisLabel: { formatter: humanFileSize } },
  series: Object.entries(store.grouped?.[store.by] ?? {}).map(([by, arr]) => ({
    name: normalizeLegendName(by),
    data: arr,
    large: true,
    type: 'line',
    // areaStyle: { },
    // stack: 'Total',
    emphasis: { focus: 'series' },
  })),
  dataZoom: [ { type: 'inside' }, { start: 0, end: 10 } ],
}));

const normalizeTableName = (item: { field: string, tolal: number}) => {
  if (store.by === 'device') return deviceNameOf(item.field);
  if (store.by === 'isp') return item.field || 'UNKNOWN'; // TODO: style __UNKNOWN__
  if (store.by === 'adapter') {
    if (item.field === 'NetworkShare Client') return 'Pair & Share Client';
    if (item.field === 'NetworkShare Host') return 'Pair & Share Host';
  }
  // Ethernet
  // Wi-Fi
  // Cellular
  // VPN
  // Loopback
  // Bluetooth
  // NetworkShare Client
  // NetworkShare Host
  // Unknown
  return item.field;
};

const license = computed({
  get: () => store.license,
  set: x => { store.license = x; void store.fetch() },
})
// TODO: dedicated_server, router licenses
const filterByOpts = computed<LicenseOption[]>(() => {
  const hashset = new Set<string>(); // XXX: ridiculously no true hashset

  for (const sub of subscriptions.value ?? []) {
    switch (sub.type) {
      case 'individual':
        hashset.add(JSON.stringify({ type: sub.type }));
        break;
      case 'teams': {
        if (!isOwnerOrAdmin(sub)) break;

        hashset.add(JSON.stringify({ type: sub.type, team: { name: sub.team!.name, id: sub.team!.teamId }}));
        break;
      }
      case 'families':
        hashset.add(JSON.stringify({ type: sub.type, team: { name: sub.team!.name, id: sub.team!.teamId }}));
        break;
      case 'dedicated_server':
        hashset.add(JSON.stringify({ type: sub.type }));
        break;
      case 'router':
        // TODO: hashset.add(JSON.stringify({ type: sub.type }));
        break;
    }
  }

  return Array.from(hashset).map(x => JSON.parse(x));
});
const report = ref('Total Usage');
const by = computed({
  get: () => ({ code: store.by, name: { isp: 'ISP', adapter: 'Adapter Type', device: 'Device', server: 'Server' }[store.by] }),
  // FIXME: how to disbale rectivity on mutating store.by?
  set: ({ code }) => { store.by = code; void store.fetch() },
});

const breakdownOpts = [
  { name: 'Device', code: 'device' },
  { name: 'ISP', code: 'isp' },
  { name: 'Adapter Type', code: 'adapter' },
  { name: 'Server', code: 'server' },
];

// TODO: platform, speedify version
const teamDeviceNameOf = (uuid: string) => Object.fromEntries(store.__tunnel?.devices?.map(x => ([x.deviceUUID, x])) ?? [])[uuid]?.friendlyName;
const deviceNameOf = (uuid: string) => linkedDevicesStore.nameof(uuid) || teamDeviceNameOf(uuid) || 'UNNAMED ' + uuid.slice(0, 8).toUpperCase();
const hourFormat = Intl.DateTimeFormat(navigator.language, { hour: 'numeric' }).resolvedOptions().hour12 ? '12' : '24';
import.meta.env.DEV && expose({ report, store, option, hourFormat, deviceNameOf });

onMounted(async () => {
  await linkedDevicesStore.refresh();
  await store.fetch();
});

const licenseNameOf = (opt: LicenseOption) => {
  switch (opt.type) {
    case 'individual':
      return 'Individual';
    case 'dedicated_server':
      return 'My Dedicated Server';
    case 'families':
      return 'My Family';
    case 'router':
      return 'Router';
    case 'teams': {
      // Call it 'Team' when there is only one. Teams are usually unnamed
      const numTeams = subscriptions.value.map(x => x.type === 'teams').reduce((acc, curr) => acc + +curr, 0);
      return numTeams === 1 ? 'My Team' : `Team (${opt.team!.id})`;
    }
  }
};

const formatDurationSeconds = (secs: number) =>
  formatDuration(intervalToDuration({ start: 0, end: secs * 1000 }), {
    format: ['years', 'months', 'days', 'hours', 'minutes'],
  }) || 0;
</script>
