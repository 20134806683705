<template>
  <div class="container-fluid">
    <div class="router-card">
      <InputText v-model="value" type="text" :invalid="invalid" placeholder="Enter your Activation Code" v-on:input="update" />
      <div v-if="invalid" style="color: #ff4f44">Invalid code</div>
    </div>

    <div class="container mt-2">
      <div class="row column-gap-3">
        <Button
          label="Go Back"
          class="col btn btn-speedify-grey stm-btn-cancelation-flow-go-back-1"
          style="flex: 1 1 0"
          @click="
            back();
            dialogRef.close();
          "
        />
        <Button
          label="Redeem"
          class="col btn btn-speedify-blule stm-btn-cancelation-flow-go-back-1"
          style="flex: 1 1 0"
          @click="redeem()"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, inject, markRaw } from 'vue';

import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { useDialog } from 'primevue/usedialog';

import DialogRouterPlansPricing from '@/components/DialogRouterPlansPricing.vue';
import DialogRouterActivated from './DialogRouterActivated.vue';
import { redeemRouterCoupon } from '@/services/backend';
import RouterPlansPricingHeader from './RouterPlansPricingHeader.vue';

const dialog = useDialog();
const dialogRef: any = inject('dialogRef');
const value = ref('');
const invalid = ref(false);

const back = () =>
  dialog.open(DialogRouterPlansPricing, {
    props: {
      modal: true,
      draggable: false,
    },
    templates: { header: markRaw(RouterPlansPricingHeader) },
  });

const redeem = async () => {
  const result = await redeemRouterCoupon(value.value);

  if (result.error) {
    invalid.value = true;
  } else {
    dialog.open(DialogRouterActivated, {
      props: {
        modal: true,
        draggable: false,
        header: 'Success!',
      },
    });
  }
};

const update = () => {
  invalid.value = false;
};
</script>

<style type="text/css" media="screen" scoped>
.router-card {
  border: none;
  margin-top: 1em;
}

.router-font-grey {
  font-size: 0.8em;
  color: #999999;
}
</style>
