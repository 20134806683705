<template>
  <div class="container justify-content-center text-center">
    <Message :severity="severity" :closable="false" pt:text:class="mx-auto">
      <template #icon>{{}}</template>
      <div>
        Your linked router requires a
        <span class="fw-bold">Speedify for Routers</span>
        license.
      </div>
      <router-link
        v-if="canManageRouter(subscription)"
        class="stm-btn-manage-subscription"
        :to="{ name: 'SubscriptionPortal', query: { referenceId: subscription?.referenceId } }"
      >
        Manage Routers
      </router-link>
      <span v-if="canManageRouter(subscription)" style="color: grey"> | </span>
      <a @click="viewPlansPricing">View Router Plans and Pricing</a>
    </Message>
  </div>
</template>

<script setup lang="ts">
import { markRaw, ref } from 'vue';

import Message from 'primevue/message';
import { MessageProps } from 'primevue/message';
import { useDialog } from 'primevue/usedialog';

import { Subscription } from '@/model';
import { isOwnerOrAdmin } from '@/services/subscription';
import DialogRouterPlansPricing from '../DialogRouterPlansPricing.vue';
import RouterPlansPricingHeader from '../RouterPlansPricingHeader.vue';

defineProps<{ subscription?: Subscription }>();

const severity = ref<MessageProps['severity']>('error');
const dialog = useDialog();
const canManageRouter = (subscription?: Subscription) =>
  subscription &&
  !subscription?.upgradeDetails?.currentProduct?.unregisteredProduct &&
  isOwnerOrAdmin(subscription) &&
  subscription.type === 'router';
const viewPlansPricing = () =>
  dialog.open(DialogRouterPlansPricing, {
    props: {
      modal: true,
      draggable: false,
    },
    templates: { header: markRaw(RouterPlansPricingHeader) },
  });
</script>

<style type="text/css" media="screen" scoped>
.p-message-success {
  background: #fef3f2;
  outline-color: #f5c6cb;
  color: black;
}

.p-message-error {
  background: #fef3f2;
  outline-color: #f5c6cb;
  color: black;
}

.p-message .p-message-text div {
  font-weight: normal;
}

.p-message-text a {
  color: #00adee;
  text-decoration: none;
}
</style>
