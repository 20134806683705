<template>
  <PageNavbar />
  <!-- no interesting styling. we have no renders or direction -->
  <div v-if="state.pending" id="content">Please wait while we redeem your coupon!</div>
  <div v-else id="content">
    <p v-if="state.success">Success! Your coupon has been redeemed and a license has been provisioned for your account.</p>
    <p v-else>
      Something went wrong redeeming your coupon. Please attempt again in a few minutes or email support@speedify.com for help.
    </p>
    You will be redirected back to the dashboard in 10 seconds.
  </div>
  <PageFooter />
</template>

<script setup lang='ts'>
import { onMounted, onBeforeMount, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { redeemRouterCoupon } from '@/services/backend';
import PageFooter from '@/components/PageFooter.vue';
import PageNavbar from '@/components/PageNavbar.vue';

const route = useRoute();
const router = useRouter();

const { coupon } = route.query as { [key: string]: string };

const state = reactive({
  pending: true,
  success: false,
});

onBeforeMount(async () => {
  if (!coupon) {
    await router.push('/account');
  }
});

onMounted(async () => {
  try {
    const result = await redeemRouterCoupon(coupon!);
    state.success = !result.error;
  } catch (_) {
    state.success = false;
  }
  state.pending = false;

  setTimeout(() => void router.push('/account'), 10000);
});

</script>
