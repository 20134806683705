<template>
  <div class="container-fluid">
    <div class="router-card">
      <h4 class="fw-bold">Your “Powered By Speedify” router has been activated</h4>
      <div class="my-2">We have automatically assigned this device to your open router license.</div>

      <div class="container">
        <div class="row column-gap-3">
          <Button
            label="OK"
            class="col btn btn-speedify-blue stm-btn-cancelation-flow-go-back-1"
            style="flex: 1 1 0"
            @click="back()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { inject, markRaw } from 'vue';
import { useDialog } from 'primevue/usedialog';

import Button from 'primevue/button';
import DialogRouterPlansPricing from './DialogRouterPlansPricing.vue';
import RouterPlansPricingHeader from './RouterPlansPricingHeader.vue';

const dialog = useDialog();
const dialogRef: any = inject('dialogRef');

const back = () => {
  dialogRef.value.close();
  dialog.open(DialogRouterPlansPricing, {
    props: {
      modal: true,
      draggable: false,
    },
    templates: { header: markRaw(RouterPlansPricingHeader) },
  });
};
</script>

<style type="text/css" media="screen" scoped>
.p-dialog-header {
  padding: 1em 0 0 0 !important;
  border-bottom: 1px solid #e4e7ec;
}

.p-dialog-content {
  padding: 0 0 1em 0 !important;
}

.router-card {
  border: none;
  padding: 1em;
  margin-top: 1em;
}

.p-dialog button {
  border: 1px #f2f4f7 !important;
}
</style>
