import { defineStore } from 'pinia';
import { Mutex } from 'async-mutex';

import { getUser } from '@/services/backend';
import { ActiveDevice, Subscription, User, Role, PrivateServer } from '../model';

export interface Store extends User {
  servers: PrivateServer[],
  subscriptions: Subscription[],
  freeLicenseDataGB: number,
  usedDataGB: number,
  success: boolean,
  recurlyAccountCode: string | null,
}

const makeDefaultData = () => ({
  mutex: new Mutex(),
  success: false,

  userid: 0,
  email: '',
  title: '',
  licenses: [],
  servers: [] as PrivateServer[],
  activeDevices: [] as ActiveDevice[],
  subscriptions: [] as Subscription[],
  freeLicenseDataGB: 0,
  usedDataGB: 0,
  availableDeviceLimit: 5,
  recurlyAccountCode: null,
});

export const useUserStore = defineStore('user', {
  state: (): Store & { mutex: Mutex; } => makeDefaultData(),
  getters: {
    blackfriday24EligibleUpgrades(state): boolean {
      return state.subscriptions.some(s => s.upgradeDetails?.upgradeOptions?.some(u => u.blackfriday24EligibleUpgrade));
    },
    routerSubscriptions(state): Subscription[] {
      return state.subscriptions.filter(s => s.productName.match(/router/i) && !s.productName.match(/promo/i));
    },
    routerQty(): number {
      return this.routerSubscriptions.reduce((a, v) => a + v.quantity, 0);
    },
    hasTeamSubscription(state) {
      return state.subscriptions.filter(s => s.type === 'teams' && s.active);
    },
    teams(state) {
      return state.subscriptions.map(s => s.type === 'teams' && s.team).filter(s => s);
    },
    teamRouterAssociations(state) {
      const teamRouterMapping = state.subscriptions.reduce((a, v) => {
        let routerSubs: string[] = [];
        if (v.type === 'teams') {
          routerSubs = v.team!.associatedRouterSubContexts?.map(c => c.routerReferenceId) || [];
        }
        a[v.referenceId] = routerSubs;
        return a;
      }, {} as Record<string, string[]>);
      return teamRouterMapping;
    },
    dedicatedServerQuantity(state) {
      return state.subscriptions
        .filter(e => e.type === 'dedicated_server')
        .reduce((a, v) => a + v.quantity, 0);
    },
    teamServers(state) { return state.servers.filter((s: PrivateServer) => s.teamId !== -1) },
    privateServers(state) { return state.servers.filter((s: PrivateServer) => s.teamId === -1) },
  },
  actions: {
    // state.$reset is not available for <script setup>
    // https://stackoverflow.com/questions/71690883/pinia-reset-alternative-when-using-setup-syntax
    reset() {
      this.$state = makeDefaultData();
    },
    async refresh(force: boolean = false) {
      await this.mutex.runExclusive(async () => {
        if (force) {
          this.success = false;
        }

        let data; // TODO: refactor me
        if (force || ((this.userid === 0 && this.email === '') || !this.success)) {
          data = await getUser();
          if (!data?.userid) return this.success = false;

          const { userid, email, activeDevices, servers, availableDeviceLimit, recurlyAccountCode } = data;
          this.userid = userid;
          this.email = email.toLowerCase();
          this.activeDevices = activeDevices;
          this.availableDeviceLimit = availableDeviceLimit;
          this.recurlyAccountCode = recurlyAccountCode;

          // TODO:
          // This includes both private individual servers as well as teams servers
          // do we need to filter at all? discussion is needed
          this.servers = servers as PrivateServer[];
        }

        if (!this.success) {
          this.subscriptions = data.subscriptions;
          this.freeLicenseDataGB = data.freeLicenseDataGB;
          this.usedDataGB = data.usedDataGB;
          this.success = true;
        }
      });
    },
    removeActiveDevice(uuid: string) {
      this.activeDevices = this.activeDevices.filter(d => d.deviceUuid !== uuid);
    },
    appendAPIKeyToSubscription(referenceId: string, apiKey: string, description: string, allowedIPs: string) {
      this.subscriptions.map((s) => {
        if (s.referenceId === referenceId) {
          s.team!.apiKeys.push({
            apiKey,
            description,
            dateGenerated: new Date(),
            keyId: 0,
            userid: 0,
            teamId: 0,
            role: 'user',
            expirationDate: new Date(),
            invalidated: 0,
            allowedIPs,
          });
        }
      });
    },
    revokeAPIKeyFromSubscription(referenceId: string, apiKey: string) {
      this.subscriptions.map((s) => {
        if (s.referenceId === referenceId) {
          // We want to delete the api key which has been revoked
          s.team!.apiKeys = s.team!.apiKeys.filter((k) => k.apiKey !== apiKey);
        }
        return s;
      });
    },
    modifyAPIKeyForSubscription(referenceId: string, apiKey: string, allowedIPs: string) {
      this.subscriptions.forEach((s) => {
        if (s.referenceId === referenceId) {
          // We want to delete the api key which has been revoked
          s.team!.apiKeys = s.team!.apiKeys.map((k) => {
            if (k.apiKey === apiKey) {
              k.allowedIPs = allowedIPs;
            }
            return k;
          });
        }
        return s;
      });
    },
    addTeamMember(referenceId: string, email: string, role: Role) {
      this.subscriptions.map((s) => {
        if (s.referenceId === referenceId) {
          s.team!.teamMembers.push({
            dateGenerated: new Date(),
            email,
            role,
            usageBytesThisPeriod: 0,
            usedGB: 0,
            firstName: null,
            lastName: null,
            teamId: 0,
            userid: 0,
          });
        }
        return s;
      });
    },
    addPendingTeamMember(referenceId: string, email: string) {
      this.subscriptions.map((s) => {
        if (s.referenceId === referenceId) {
          s.team!.pendingTeamInvitations.push(email);
        }
        return s;
      });
    },
    removeTeamMember(referenceId: string, email: string) {
      this.subscriptions.map((s) => {
        if (s.referenceId === referenceId) {
          // We want to delete the user object which was removed from the team
          s.team!.teamMembers = s.team!.teamMembers.filter((m) => m.email !== email);
        }
        return s;
      });
    },
    updateSeatQuantity(referenceId: string, quantity: number) {
      this.subscriptions.map((s) => {
        if (s.referenceId === referenceId) {
          s.quantity = quantity;
        }
        return s;
      });
    },
    updateTeamMemberRole(referenceId: string, userid: number, role: Role) {
      this.subscriptions.map((s) => {
        if (s.referenceId === referenceId) {
          s.team!.teamMembers.map((member) => {
            if (member.userid === userid) {
              member.role = role;
            }
          });
        }
        return s;
      });
    },
    delistBlackFriday24PromotionByReferenceId(referenceId: string) {
      this.subscriptions.map((s) => {
        if (s.referenceId === referenceId) {
          s.blackfriday24Eligible = false;
          s.upgradeDetails!.upgradeOptions = s.upgradeDetails!.upgradeOptions.map(e => ({ ...e, blackfriday24EligibleUpgrade: false }));
        };
        return s;
      });
    },
  },
});
