<template>
  <!-- eslint-disable vuejs-accessibility/label-has-for -->

  <!-- add team member modal -->
  <div
    v-if="state.showAddTeamMemberModal"
    id="addTeamMemberModal"
    class="modal fade"
    data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addTeamMemberLabel" aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="addTeamMemberLabel" class="modal-title">Add a Member</h5>
          <button
            type="button"
            class="btn-close stm-btn-add-team-member-cancel"
            data-bs-dismiss="modal" aria-label="Close"
            @click="dismissAddTeamMemberModal"
          />
        </div>
        <form @submit.prevent="addTeamMember">
          <div class="modal-body">
            <div v-if="!state.newMember.success">
              <div v-if="subscription.team!.teamMembers.length >= subscription.quantity" class="alert alert-warning" role="alert">
                <!-- eslint-disable-next-line max-len -->
                <i class="fas fa-exclamation-circle"></i>&nbsp;Your team is at capacity. If another user is added to the team, your account will be billed per extra user.
              </div>
              <div class="form-floating">
                <input
                  id="newTeamMemberEmail"
                  v-model="state.newMember.email"
                  type="email"
                  autocomplete="off"
                  class="form-control"
                  placeholder="" aria-label="New Member's Email"
                  aria-describedby="newTeamMemberEmail"
                />
                <label for="newTeamMemberEmail">New Member's Email</label>
              </div>
              <small v-if="state.newMember.email === '' || v$.newMember.email.email.$invalid" style="color: red;">Please enter a valid email</small>
              <small
                v-if="!v$.newMember.email.email.$invalid && v$.newMember.email.isUnique.$invalid"
                style="color: red;"
              >
                This email is already registered to the {{ teamType }}.
              </small>
              <div class="form-floating mt-3">
                <input
                  id="newTeamMemberFirstName"
                  v-model="state.newMember.firstName"
                  type="text"
                  class="form-control"
                  placeholder="" aria-label="New Member's First Name"
                  aria-describedby="newTeamMemberFirstName"
                >
                <label for="newTeamMemberFirstName">New Member's First Name</label>
              </div>
              <div class="form-floating mt-3">
                <input
                  id="newTeamMemberLastName"
                  v-model="state.newMember.lastName"
                  type="text"
                  class="form-control" placeholder="" aria-label="New Member's Last Name"
                  aria-describedby="newTeamMemberLastName"
                />
                <label for="newTeamMemberLastName">New Member's Last Name</label>
              </div>
              <div class="form-floating mt-3">
                <input
                  id="newTeamMemberPassword"
                  v-model="state.newMember.password"
                  type="password"
                  autocomplete="off"
                  class="form-control" placeholder="" aria-label="New Member's Password"
                  aria-describedby="newTeamMemberPassword"
                />
                <label for="newTeamMemberPassword">New Member's Password (Optional)</label>
              </div>
              <small v-if="v$.newMember.password.minLength.$invalid" style="color: red;">
                Please enter a password of at least 8 characters in length
              </small>
              <div class="form-floating mt-3">
                <p class="mt-0 mb-1">New Member Role:</p>
                <div class="form-check">
                  <input
                    id="newTeamMemberRoleAdmin"
                    v-model="state.newMember.role"
                    value="admin"
                    class="form-check-input"
                    type="radio"
                    name="newTeamMemberRoleSelection"
                  />
                  <label class="form-check-label" for="newTeamMemberRoleAdmin">
                    Admin
                  </label>
                </div>
                <div class="form-check">
                  <input
                    id="newTeamMemberRoleUser"
                    v-model="state.newMember.role"
                    value="user"
                    class="form-check-input"
                    type="radio"
                    name="newTeamMemberRoleSelection"
                    checked
                  />
                  <label class="form-check-label" for="newTeamMemberRoleUser">
                    {{ subscription.type === 'families' ? 'Member' : 'User' }}
                  </label>
                </div>
                <div v-if="subscription.type === 'teams'" class="form-check">
                  <input
                    id="newTeamMemberRoleGuest"
                    v-model="state.newMember.role"
                    value="guest"
                    class="form-check-input"
                    type="radio"
                    name="newTeamMemberRoleSelection"
                  />
                  <label class="form-check-label" for="newTeamMemberRoleGuest">
                    Guest
                  </label>
                </div>
              </div>
            </div>
            <div
              v-if="state.newMember.success && state.newMember.invitationPending"
              class="alert alert-success mt-3 stm-msg-add-team-member-success"
              role="alert"
            >
              <!-- eslint-disable-next-line max-len -->
              <i class="fas fa-check-circle"></i>&nbsp;An email invitation has been sent to <b>{{ state.newMember.email }}</b> with instructions on joining your {{ teamType }}!
            </div>
            <div
              v-if="state.newMember.success && !state.newMember.invitationPending"
              class="alert alert-success mt-3 stm-msg-add-team-member-success"
              role="alert"
            >
              <!-- eslint-disable-next-line max-len -->
              <i class="fas fa-check-circle"></i>&nbsp;User has been successfully added to your {{ teamType }}! If the user had an account with us before, their password has <b>not</b> been updated.
            </div>
            <div
              v-if="state.newMember.error && state.newMember.isPaid"
              class="alert alert-warning mt-3 stm-msg-add-team-member-error-paid-user"
              role="alert"
            >
              <i class="fas fa-exclamation-circle"></i>&nbsp;{{ state.newMember.errorContext }}
            </div>
            <div
              v-if="state.newMember.error && !state.newMember.isPaid"
              class="alert alert-danger mt-3 stm-msg-add-team-member-error"
              role="alert"
            >
              <i class="fas fa-exclamation-circle"></i>&nbsp;Error: Unable to add user to your
              {{ teamType }}. Please try again or contact support@speedify.com
              <hr>
              {{ state.newMember.errorContext }}
            </div>
          </div>
          <div class="modal-footer">
            <button
              v-if="!state.newMember.success"
              type="button"
              class="btn btn-secondary stm-btn-add-team-member-cancel"
              data-bs-dismiss="modal"
              @click="dismissAddTeamMemberModal"
            >
              Cancel
            </button>
            <button
              v-if="state.newMember.success"
              type="button"
              class="btn btn-speedify-blue stm-btn-add-team-member-done"
              data-bs-dismiss="modal"
              @click="dismissAddTeamMemberModal"
            >
              Done
            </button>
            <button
              v-if="!state.newMember.success"
              type="submit"
              class="btn btn-speedify-blue stm-btn-add-team-member-confirm"
              :class="{
                'disabled': v$.newMember.email.$invalid
                  || v$.newMember.role.$invalid
                  || v$.newMember.password.$invalid
              }"
            >
              <span v-if="state.newMember.pending" class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
              Add to {{ subscription.type === 'teams' ? 'Team' : 'Family' }}
            </button>
          </div>
          </form>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-between align-items-end my-3">
    <div class="col-lg-6">
      <h3 class="my-2">{{ subscription.type === 'teams' ? 'Users' : 'Members' }}</h3>
      <small>Manage your {{ subscription.type === 'teams' ? 'team\'s users' : 'family members' }} and their permissions</small>
    </div>
    <div class="col-lg-6">
      <!-- this span exists for the tooltip when the button is disabled -->
      <span
        class="d-flex justify-content-end"
        tabindex="0"
        data-bs-toggle="tooltip"
        :title="addTeamMemberButtonTooltip"
      >
        <button
          type="button"
          class="btn btn-speedify-blue stm-btn-add-team-member-attempt"
          :class="{
            disabled: subscription.team!.teamMembers.length >= subscription.quantity && subscription.type === 'families',
          }"
          @click="handleShowModal('addTeamMemberModal')"
        >
          <i class="fas fa-plus"></i>&nbsp;Add {{ subscription.type === 'teams' ? 'User' : 'Member' }}
        </button>
      </span>
    </div>
  </div>

  <TeamMemberTable :subscription="subscription" :referenceId="referenceId" :teamType="teamType" />

  <div v-if="subscription.team!.pendingTeamInvitations?.length > 0" class="pt-2 pb-5">
    <h3 class="pt-3">Pending Team Member Invitations</h3>
    <div class="card">
      <div class="card-body">
        <DataTable
          :value="subscription.team!.pendingTeamInvitations.map(e => ({ email: e }))"
          tableStyle="min-width: 50rem"
        >
          <Column field="email" header="E-Mail" :sortable="true" />
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue';
import { useRoute } from 'vue-router';
import useVuelidate from '@vuelidate/core';
import {
  required,
  email,
  minLength,
} from '@vuelidate/validators';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import TeamMemberTable from '@/components/TeamMemberTable.vue';
import { addMemberToTeam } from '@/services/backend';
import { useUserStore } from '@/store/user';
import { singularSubscriptionType } from '@/services/subscription';
import { showModal } from '@/services/modal';
import { Subscription, Role } from '@/model';

const props = defineProps<{ subscription: Subscription }>();

const route = useRoute();
const { referenceId } = route.query as { referenceId: string };
const userStore = useUserStore();
const state = reactive({
  newMember: {
    email: '',
    // TODO: should we have the role set here? couldn't get vue to get the 'checked' prop
    role: 'user' as Role,
    firstName: '',
    lastName: '',
    password: '',
    success: false,
    error: false,
    isPaid: false,
    errorContext: '',
    pending: false,
    invitationPending: false,
  },
  showAddTeamMemberModal: false,
});

const handleShowModal = (elementID: string) => {
  // @ts-ignore
  state[`show${elementID.charAt(0).toUpperCase()}${elementID.slice(1)}`] = true;
  showModal(elementID);
};

const addTeamMemberButtonTooltip = computed(() => {
  const team = props.subscription.team!;
  if (team.type === 'teams' && team.teamMembers.length >= props.subscription.quantity) {
    return 'It seems like your team does not have free seats remaining. Please add more seats to your subscription.';
  }

  if (team.type === 'families' && team.teamMembers.length >= props.subscription.quantity) {
    // eslint-disable-next-line max-len
    return 'It seems like your family does not have free seats remaining. If you would like to add more members to your family, please upgrade to a teams subscription.';
  }

  return '';
});
// @ts-ignore dedicated_server not defined
const teamType = computed(() => singularSubscriptionType[props.subscription.type]);

const dismissAddTeamMemberModal = () => {
  state.newMember.email = '';
  // TODO: should we have the role set here? couldn't get vue to get the 'checked' prop
  state.newMember.role = 'user';
  state.newMember.firstName = '';
  state.newMember.lastName = '';
  state.newMember.password = '';
  state.newMember.success = false;
  state.newMember.error = false;
  state.newMember.isPaid = false;
  state.newMember.errorContext = '';
  state.newMember.pending = false;
  state.newMember.invitationPending = false;
};

const addTeamMember = async () => {
  state.newMember.success = false;
  state.newMember.error = false;
  state.newMember.isPaid = false;
  state.newMember.errorContext = '';
  state.newMember.pending = true;

  const response = await addMemberToTeam(
    referenceId,
    state.newMember.email,
    state.newMember.role,
    state.newMember.firstName,
    state.newMember.lastName,
    state.newMember.password,
  );

  state.newMember.pending = false;
  if (response.resp.memberInvitationStatus === 'invited') {
    state.newMember.invitationPending = true;
  }

  if (response.error) {
    state.newMember.error = true;
    state.newMember.isPaid = response.isPaid;
    // @ts-ignore
    state.newMember.errorContext = response.context;
    return;
  }

  state.newMember.success = true;

  if (state.newMember.invitationPending) {
    userStore.addPendingTeamMember(referenceId, state.newMember.email);
  } else {
    userStore.addTeamMember(referenceId, state.newMember.email, state.newMember.role);
    // backend will update the quantity automatically so let's reflect that
    if (props.subscription.type === 'teams' && props.subscription.team!.teamMembers.length >= props.subscription.quantity) {
      userStore.updateSeatQuantity(referenceId, props.subscription.quantity + 1);
    }
  }
};

const rules = computed(() => ({
  newMember: {
    role: {
      required,
    },
    email: {
      required,
      email,
      isUnique(value: string) {
        if (value === '') {
          return true;
        }
        const existsInCurrentTeam = !!props.subscription.team!.teamMembers.find((m) => m.email === value);
        return !existsInCurrentTeam;
      },
    },
    password: {
      minLength: minLength(8),
    },
  },
}));

const v$ = useVuelidate(rules, state);
</script>
