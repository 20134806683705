import { defineStore } from 'pinia';

interface Store {
  showRouterPlansPricing: boolean;
  deviceLoggedIn: string | false;
}

export const useDialogStore = defineStore('dialog', {
  state: (): Store => ({
    showRouterPlansPricing: true,
    deviceLoggedIn: false,
  }),
  // validd thru the entire session
  // should be reset before immediately after login
  persist: true,
});
