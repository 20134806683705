<template>
  <AccountSelection v-if="state.showAcctSel" :email="state.email" v-on:token-refreshed="onReceiveRefresh" />

  <div v-else>
    <div v-if="state.shouldRenderActivationCode" v-show="!isFromApp">
      <PageNavbar />
      <div id="content" class="position-absolute top-50 start-50 translate-middle d-flex flex-column align-items-center">
        <ActivationCode v-on:tried-activation="onTriedActivation" />
        <span v-if="state.activationResult === 'Error'" style="color: red">Something went wrong.</span>
        <span v-if="state.activationResult === 'Unrecognized'" style="color: red">Unrecognized activation code</span>
        <span v-if="state.activationResult === 'Success'" style="color: green">Activation succeeded.</span>
        <span v-if="state.activationResult === 'Success' && !isFromApp" class="text-center">
          You will be redirected in 5 seconds to your account dashboard. If you haven't yet, please click
          <router-link to="/account">here</router-link>
        </span>
      </div>
      <PageFooter />
    </div>

    <div v-if="isFromApp">
      <div v-if="state.activationResult === null" class="container">
        <div class="container d-flex vh-90 flex-column justify-content-center align-items-center">
          <div class="spinner-border text-info mb-3" role="status" />
          <span class="text-center mt-3">Signing in...</span>
        </div>
      </div>

      <div v-else-if="state.activationResult === 'Success'" class="container">
        <div class="container d-flex vh-90 flex-column justify-content-center align-items-center">
          <div><SpeedifyLogo /></div>
          <span class="text-center mt-3">You may now close this window</span>
        </div>
      </div>

      <div v-else-if="state.activationResult === 'Error'" class="container">
        <div class="container d-flex vh-90 flex-column justify-content-center align-items-center">
          <div class="text-center mt-3">Sorry, something went wrong</div>
          <br />
          <!-- It would be one step fewer for the user if we could provide the app Sign In button here -->
          <span class="text-center mt-3">Please select Sign In from the Speedify app again to finish Signing In</span>
        </div>
      </div>

      <!-- The login happens quickly when isFromApp so we might not see a quirky error here ever -->
      <div v-else-if="state.activationResult === 'Unrecognized'" class="container">
        <div class="container d-flex vh-90 flex-column justify-content-center align-items-center">
          <div class="text-center mt-3">Sorry, the Sign In session cannot be recognized</div>
          <br />
          <span class="text-center mt-3">Please select Sign In from the Speedify app again to finish Signing In</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts" charset="utf-8">
import { onBeforeMount, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import wretch from 'wretch';

import PageNavbar from '@/components/PageNavbar.vue';
import PageFooter from '@/components/PageFooter.vue';
import SpeedifyLogo from '@/components/SpeedifyLogo.vue';
import AccountSelection from '@/components/AccountSelection.vue';
import ActivationCode from '@/components/ActivationCode.vue';

import { getToken } from '@/services/backend';
import { useDialogStore } from '@/store/dialog';

const route = useRoute();
const router = useRouter();
const dialogStore = useDialogStore();
const isFromApp = route.query.fromapp === '1';
const role = route.query.role ?? 'client';

type Result = 'Success' | 'Unrecognized' | 'Error';

const state = reactive({
  showAcctSel: false,
  shouldRenderActivationCode: false,
  activationResult: null as Result | null,
  activationCode: '',
  email: '',
});

const onReceiveRefresh = () => {
  state.showAcctSel = false;
  state.shouldRenderActivationCode = true;
};

const onTriedActivation = async (result: Result, code: string, deviceUUID?: string) => {
  state.activationResult = result;
  state.activationCode = code;

  if (result === 'Success') {
    if (role === 'router') await wretch('/session/role').post({ role }).res();

    let timeout = null;

    if (isFromApp) {
      const openurl = `speedify://speedify.com/V2.0/activationCheck?activationCode=${code}`;
      window.location.href = openurl;
    } else {
      timeout = setTimeout(() => void router.push('/account'), 5000);
    }

    // FIXME: the above if branch

    // NOTE: by the time smv2 front end says activation success, the app/daemon may not
    // have yet made a poll request to activate the device, thus the race condition.
    // Here is a quick & dirty approach to poll the actual device activation result.
    if (role === 'router' && deviceUUID) {
      dialogStore.deviceLoggedIn = deviceUUID;
      timeout && clearTimeout(timeout);
      return await router.push('/account');
    }
  }
};

onBeforeMount(async () => {
  const token = await getToken();
  const now = Date.now();
  state.showAcctSel = !!token && now / 1000 - token.iat > 20; // seconds
  state.email = token?.email ?? '';

  state.shouldRenderActivationCode = state.showAcctSel ? false : state.activationResult === null;
});
</script>
