<template>
  <!-- API Key revocation modal -->
  <div
    v-if="state.showApiKeyRevocationModal"
    id="apiKeyRevocationModal"
    class="modal fade"
    data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="apiKeyRevocationLabel" aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="apiKeyRevocationLabel" class="modal-title">Please confirm</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="dismissRevocationRequest()"></button>
        </div>
        <div class="modal-body">
          Are you sure you want to disable this API Key?

          <!-- alerts -->
          <div v-if="state.revocationError" class="alert alert-danger stm-msg-api-key-revocation-error" role="alert">
            Something went wrong when revoking the API Key. Please try again or contact support@speedify.com
            <hr>
            {{ state.revocationErrorContext }}
          </div>

          <div v-if="state.success" class="alert alert-success stm-msg-api-key-revocation-success" role="alert">
            API Key was revoked
          </div>
        </div>

        <div class="modal-footer">
          <!-- actions -->
          <button
            v-if="state.success"
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="dismissRevocationRequest()"
          >
            Done
          </button>
          <button
            v-if="!state.success"
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="dismissRevocationRequest()"
          >
            No
          </button>
          <button v-if="!state.success" type="button" class="btn btn-danger" @click="revokeSelectedAPIKey()">Yes, I confirm</button>
        </div>
      </div>
    </div>
  </div>

  <!-- API Key allowed IPs change modal -->
  <div
    v-if="state.showApiKeyChangeAllowedIPsModal"
    id="apiKeyChangeAllowedIPsModal"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="apiKeyChangeAllowedIPsLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="apiKeyChangeAllowedIPsLabel" class="modal-title">Change IP Address or CIDR</h5>
          <button
            type="button"
            class="btn-close stm-btn-change-api-allowedips-dismiss"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="dismissAllowedIPsChangeRequest()"
          >
          </button>
        </div>
        <div class="modal-body">
          <form v-if="!state.updateAllowedIPs.success" @submit.prevent="changeAllowedIPForKey">
            <div class="form-floating mb-3">
              <input id="changeApikeyAllowedIPs" v-model="state.updateAllowedIPs.allowedIP" type="text" class="form-control">
              <!-- eslint-disable vuejs-accessibility/label-has-for -->
              <label for="changeApikeyAllowedIPs">Allowed IP addresses or CIDRs</label>
            </div>
          </form>
          <!-- alerts -->
          <div v-if="state.updateAllowedIPs.error" class="alert alert-danger stm-msg-change-api-allowedips-failed" role="alert">
            <i class="fas fa-exclamation-circle"></i>&nbsp;Unable to change ACL for your API Key. Please try again or contact support@speedify.com
            <hr>
            {{ state.updateAllowedIPs.errorContext }}
          </div>
          <div v-if="state.updateAllowedIPs.success" class="alert alert-success stm-msg-change-api-allowedips-success" role="alert">
            <i class="fa-solid fa-circle-check"></i>&nbsp;API Key IP/CIDR ACL successfully changed!
          </div>
        </div>
        <div class="modal-footer">
          <!-- actions -->
          <button
            v-if="state.updateAllowedIPs.success"
            type="button"
            class="btn btn-secondary stm-btn-change-api-allowedips-done"
            data-bs-dismiss="modal"
            @click="dismissAllowedIPsChangeRequest()"
          >
            Done
          </button>
          <button
            v-if="!state.updateAllowedIPs.success"
            type="button"
            class="btn btn-primary stm-btn-change-api-allowedips-confirm"
            @click="changeAllowedIPForKey()"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="card mb-3">
    <DataTable
      :value="apiKeys"
      responsiveLayout="scroll"
      class="p-datatable-sm"
      :paginator="true"
      :rows="5"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[5, 10]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
    >
      <!-- font-monospace comes from bootstrap -->
      <Column class="font-monospace" header="API Key">
        <template #body="slotProps">
          {{ slotProps.data.apiKey }}
          <br />
          <br />

          Allowed IPs/CIDRs: <p>{{ slotProps.data.allowedIPs }}</p>
        </template>
      </Column>
      <Column field="description" header="Description" />
      <Column field="dateGenerated" header="Generated" :sortable="true">
        <template #body="slotProps">
          {{formatDate(slotProps.data.dateGenerated)}}
        </template>
      </Column>

      <!-- actions -->
      <Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
        <template #body="slotProps">
          <!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
          <FlatPencilButtonBlue
            @click="evokeAllowedIPsChangeRequestModal(slotProps.data.apiKey)"
          />
        </template>
      </Column>
      <Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
        <template #body="slotProps">
          <!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
          <FlatGarbageButtonRed
            @click="registerAPIKeyRevocationRequest(slotProps.data.apiKey)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { reactive } from 'vue';

import { showModal } from '@/services/modal';
import { revokeAPIKey, updateAllowedIPsForAPIKey, validateAllowedIPs } from '@/services/backend';
import { useUserStore } from '@/store/user';
import FlatGarbageButtonRed from '@/components/buttons/FlatGarbageButtonRed.vue';
import FlatPencilButtonBlue from '@/components/buttons/FlatPencilButtonBlue.vue';
import { ApiKey } from '@/model';

const props = defineProps<{
  apiKeys: ApiKey[],
  referenceId: string,
}>();

const state = reactive({
  showModal: false,
  apiKey: '',
  pendingRevocation: false,
  revocationError: false,
  revocationErrorContext: '',
  success: false,
  showApiKeyRevocationModal: false,
  showApiKeyChangeAllowedIPsModal: false,
  updateAllowedIPs: {
    allowedIP: '',
    pending: false,
    success: false,
    error: false,
    errorContext: '',
  },
});
const userStore = useUserStore();

const handleShowModal = (elementID: string) => {
  // @ts-ignore
  state[`show${elementID.charAt(0).toUpperCase()}${elementID.slice(1)}`] = true;
  showModal(elementID);
};

const formatDate = (d: any) => new Date(d).toDateString();

const evokeAllowedIPsChangeRequestModal = (apiKey: string) => {
  state.apiKey = apiKey;
  state.updateAllowedIPs.allowedIP = props.apiKeys.find(e => e.apiKey === apiKey)!.allowedIPs;
  state.updateAllowedIPs.pending = true;
  handleShowModal('apiKeyChangeAllowedIPsModal');
};

const registerAPIKeyRevocationRequest = (apiKey: string) => {
  state.apiKey = apiKey;
  state.pendingRevocation = true;
  handleShowModal('apiKeyRevocationModal');
};

const dismissRevocationRequest = () => {
  state.showModal = false;
  state.apiKey = '';
  state.pendingRevocation = false;
  state.revocationError = false;
  state.revocationErrorContext = '';
  state.success = false;
};

const dismissAllowedIPsChangeRequest = () => {
  state.apiKey = '',
  state.updateAllowedIPs.pending = false;
  state.updateAllowedIPs.success = false,
  state.updateAllowedIPs.error = false;
  state.updateAllowedIPs.errorContext = '';
};

const revokeSelectedAPIKey = async () => {
  try {
    const result = await revokeAPIKey(props.referenceId, state.apiKey);
    if (result.error) {
      throw result.response;
    }

    userStore.revokeAPIKeyFromSubscription(props.referenceId, state.apiKey);
    state.success = true;
  } catch (err) {
    state.revocationError = true;
    state.revocationErrorContext = err as string;
  }
};

const changeAllowedIPForKey = async () => {
  try {
    const validations = await validateAllowedIPs(state.updateAllowedIPs.allowedIP);

    if (!validations.error) {
      const failedValidations = validations.filter(e => !e.valid);
      if (failedValidations.length > 0) {
        state.updateAllowedIPs.error = true;
        state.updateAllowedIPs.errorContext = `Invalid IP Addresses or CIDRs entered: ${failedValidations.map(e => e.query).join(', ')}`;
        state.updateAllowedIPs.pending = false;
        return;
      }
    } else {
      state.updateAllowedIPs.error = true;
      state.updateAllowedIPs.pending = false;
      return;
    }
  } catch (err) {
    console.error(err);
    state.updateAllowedIPs.error = true;
    // @ts-ignore
    state.updateAllowedIPs.errorContext = err.message || err;
    return;
  }

  state.updateAllowedIPs.error = false;
  state.updateAllowedIPs.errorContext = '';

  try {
    const result = await updateAllowedIPsForAPIKey(
      props.referenceId,
      state.apiKey,
      state.updateAllowedIPs.allowedIP,
    );
    if (result.error) {
      throw result.response;
    }

    userStore.modifyAPIKeyForSubscription(
      props.referenceId,
      state.apiKey,
      state.updateAllowedIPs.allowedIP,
    );
    state.updateAllowedIPs.success = true;
  } catch (err) {
    state.updateAllowedIPs.error = true;
    state.updateAllowedIPs.errorContext = err as string;
  };
};
</script>
