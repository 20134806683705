<template>
  <PageNavbar />
  <div id="content">
  <Banner />
  <!-- eslint-disable vuejs-accessibility/form-control-has-label, vuejs-accessibility/anchor-has-content -->
  <div class="container mt-5">
    <nav aria-label="breadcrumb my-3">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><router-link :to="{ name: 'AccountDashboard' }">Dashboard</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">Billing Information</li>
      </ol>
    </nav>
    <Message v-if="Boolean(msg)" :severity="msg?.severity" :closable="false" pt:text:class="mx-auto">
      <template #icon>{{}}</template>
      <component :is="msg?.content" />
      <div v-if="msg?.context" style="white-space: pre-wrap">
        <hr />
        {{ msg?.context }}
      </div>
    </Message>
    <h1 class="">Billing Information</h1>

    <h3 class="my-3">
      {{ humanReadableProductName(subscription.productName) }}
      {{ hyphenate(capitalizeFirstChar(subscriptionPeriod(subscription.productName))) }}
    </h3>
    <div class="row mb-5 mt-5">
      <div class="col-sm">
        <div class="card">
          <div class="card-body mb-3">
            <div class="card-title">
              <small>Subscription active since</small>
            </div>
            <h5>{{ new Date(subscription.createdAt).toLocaleDateString() }}</h5>
          </div>
        </div>
      </div>
      <div v-if="renewalDate.toDateString() !== new Date(0).toDateString()" class="col-sm">
        <div class="card">
          <div class="card-body mb-3">
            <div class="card-title">
              <small>
                Renews {{ subscriptionPeriod(subscription.productName) }}
                for
                <b>
                  {{ subscription.currency }} {{ Number(subscription.total - subscription.discount).toFixed(2) }}
                </b>
                on
              </small>
            </div>
            <h5>{{ renewalDate.toDateString() }}</h5>
          </div>
        </div>
      </div>
      <div v-else-if="!subscription.cancelable" class="col-sm">
        <div class="card border-danger">
          <div class="card-body mb-3">
            <div class="card-title">
              <small>Expires on</small>
            </div>
            <h5>{{ expiresOn() }}</h5>
          </div>
        </div>
      </div>
    </div>

    <div v-if="subscription.storeType === 'REC'" class="row d-flex flex-wrap justify-content-between align-items-center">
      <div>
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <h3>Invoices</h3>
            <small>View past account activity</small>
          </div>

          <div v-if="isOwner(subscription)" class="col-md-6 col-sm-12">
            <span class="d-flex justify-content-md-end justify-content-sm-between mt-3" style="gap: 1em">
              <a type="button" class="btn btn-speedify-outline stm-btn-update-billing-info" :href="subscription.billingInfoUrl">
                <i class="far fa-edit"></i>
                &nbsp;Modify Billing
              </a>

              <!-- Cancellation flow -->
              <button
                v-if="isPausable(subscription)"
                type="button"
                class="btn btn-speedify-outline ml-3 stm-btn-open-cancelation-flow"
                @click="showModal('Pause')"
              >
                <i class="far fa-times-circle"></i>
                &nbsp;Pause Subscription
              </button>

              <button
                v-if="isCancelable(subscription)"
                type="button"
                class="btn btn-speedify-outline ml-3 stm-btn-open-cancelation-flow"
                @click="clickCancel()"
              >
                <i class="far fa-times-circle"></i>
                &nbsp;Cancel Subscription
              </button>

              <!-- Uncancel -->
              <button
                v-if="isUncancelable(subscription)"
                type="button"
                class="btn btn-speedify-outline ml-3 stm-btn-uncancel-attempt"
                @click="uncancelSubscription"
              >
                <i class="fa fa-undo"></i>
                &nbsp;Reactivate Subscription
              </button>
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="card mt-3 mb-4 p-0">
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-hover mb-0">
                <thead class="table-light">
                  <tr>
                    <th scope="col">Invoice #</th>
                    <th scope="col">Issued On</th>
                    <th scope="col">Due By</th>
                    <th scope="col">Status</th>
                    <th scope="col">Amount</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="i in subscription.invoices" :key="i.invoiceNumber">
                    <td>{{ i.invoiceNumber }}</td>
                    <td>{{ new Date(i.createdAt).toLocaleDateString() }}</td>
                    <td v-if="i.dueDate">{{ new Date(i.dueDate).toLocaleDateString() }}</td>
                    <td v-else></td>
                    <td>{{ i.state.replace(/^\w/, c => c.toUpperCase()) }}</td>
                    <td>{{ i.currencyCode }} {{ Number(i.total).toFixed(2) }}</td>
                    <td>
                      <a class="stm-btn-open-billing-info-recurly-pdf" :href="i.pdfLink" download>
                        <i class="fas fa-cloud-download-alt"></i>
                      </a>
                    </td>
                    <td>
                      <a
                        class="stm-btn-open-billing-info-recurly"
                        :href="i.invoiceLink"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i class="fas fa-external-link-alt"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="subscription.storeType === 'FSC'" class="card text-center">
      <div class="card-body my-3">
        <div class="card-title">
          <a :href="invoiceLink" class="text-decoration-none">
            Please click here to view your subscription information, invoices, and information about cancellation.
          </a>
        </div>
      </div>
    </div>
    <div v-else-if="subscription.storeType === 'FS'" class="card text-center">
      <div class="card-body my-3">
        <div class="card-title">
          <a :href="invoiceLink" class="text-decoration-none">
            Please click here to view your subscription information, invoices, and information about cancellation.
          </a>
        </div>
      </div>
    </div>
    <div v-else-if="subscription.storeType === 'AS'" class="card text-center">
      <div class="card-body my-3">
        <div class="card-title">
          <a :href="invoiceLink" class="text-decoration-none">
            Please click here to view your subscription information, invoices, and information about cancellation on the Apple App
            Store.
          </a>
        </div>
      </div>
    </div>
    <div v-else-if="subscription.storeType === 'GP'" class="card text-center">
      <div class="card-body my-3">
        <div class="card-title">
          <a :href="invoiceLink" class="text-decoration-none">
            Please click here to view your subscription information, invoices, and information about cancellation on the Google
            Play Store.
          </a>
        </div>
      </div>
    </div>
    <div v-else class="card text-center">
      <div class="card-body my-3">
        <div class="card-title">
          <h5>
            It seems like your subscription was purchased on a store that is not our primary processor. Please contact support for
            information on your invoices.
          </h5>
        </div>
      </div>
    </div>

    <!-- cancellation flow -->
    <PerksModal />
    <PauseModal />
    <OfferModal v-if="subscription?.storeType === 'REC'" v-on:tried-coupon="onRedeemCoupon" />
    <RemoveInactiveSeatsModal v-on:tried-remove-inactive-seats="onRemoveInactiveSeats" />
    <AreYouSureModal v-on:tried-cancellation="onCancel" />
  </div>
  </div>
  <PageFooter />
</template>

<script setup lang="ts">
import { useRoute, RouterLink } from 'vue-router';
import { h, provide, ref, computed, onMounted } from 'vue';
import type { Component } from 'vue';

import Message from 'primevue/message';
import { MessageProps } from 'primevue/message';

import {
  isOwner,
  subscriptionPeriod,
  isCancelable,
  isPausable,
  isUncancelable,
  shortRenewalSummary as subscriptionRenewalDate,
  humanReadableProductName,
} from '@/services/subscription';
import { getToken, uncancelSubscriptionByReferenceId } from '@/services/backend';
import { hyphenate, capitalizeFirstChar } from '@/services/string';
import { useUserStore } from '@/store/user';
import { useLinkedDevicesStore } from '@/store/linkedDevices';
import PageNavbar from '@/components/PageNavbar.vue';
import PageFooter from '@/components/PageFooter.vue';
import PauseModal from '@/components/billing/PauseModal.vue';
import AreYouSureModal from '@/components/billing/AreYouSureModal.vue';
import PerksModal from '@/components/billing/PerksModal.vue';
import OfferModal from '@/components/billing/OfferModal.vue';
import RemoveInactiveSeatsModal from '@/components/billing/RemoveInactiveSeatsModal.vue';
import { modal } from '@/symbol';
import Banner from './Banner.vue';
import { useBanner } from './usebanner';
import BannerRouterLicenseRequired from './banners/BannerRouterLicenseRequired.vue';

const route = useRoute();
const banner = useBanner();
// TODO: use in route meta https://router.vuejs.org/guide/advanced/meta.html
const { referenceId } = route.query as { [key: string]: string };
const userStore = useUserStore();
const linkedDevicesStore = useLinkedDevicesStore();
let { subscriptions } = userStore;
const msg = ref<{ severity: MessageProps['severity']; content: Component; context?: string }>();

const currModal = ref<string | null>(null);
const showModal = (name: string | null) => (currModal.value = name);
provide(modal, { currModal, data: ref(null), showModal });

const subscription = computed(() => subscriptions.find(s => s.referenceId === referenceId)!);
// @ts-expect-error FIXME: should .url be .invoiceLink or .pdfLink?
const invoiceLink = computed(() => subscription.value.invoices[0].url);
const renewalDate = computed(() => subscriptionRenewalDate(subscription.value));

onMounted(async () => {
  const numRouterSubs = userStore.routerQty;
  const payload = await getToken();

  if (payload?.role === 'router' && numRouterSubs === 0) {
    banner.open(BannerRouterLicenseRequired, { props: { subscription } });
  } else if (payload?.role === 'router' && numRouterSubs < linkedDevicesStore.routers.length) {
    banner.open(BannerRouterLicenseRequired, { props: { subscription } });
  }
});

const clickCancel = () => showModal(subscription.value.storeType === 'REC' ? 'Offer' : 'AreYouSure');

const onCancel = async (result: 'success' | 'failure', message?: string | Error) => {
  const severity = 'error';

  switch (result) {
    case 'success': {
      // NOTE: endDate for a dedicated server sub is epoch 0;
      const endDate = new Date(subscription.value.endDate ?? 0);
      const date = endDate.getTime() === 0 ? new Date(subscription.value.nextPeriodDate!) : endDate;
      const content = () => `Your Speedify subscription has been cancelled and is set to expire at the end of your
        current billing cycle on ${date.toDateString()}.`;
      msg.value = { severity, content };
      break;
    }
    case 'failure': {
      const content = () =>
        'There was an issue cancelling your subscription. Please try again in a few minutes or contact our support team at support@speedify.com';
      const context = String(message || '');
      msg.value = { severity, content, context };
      break;
    }
  }
  await userStore.refresh();
};

const uncancelSubscription = async () => {
  const response = await uncancelSubscriptionByReferenceId(referenceId!);
  if (response.error) {
    const severity = 'error';
    const content = () =>
      'There was an issue reactivating your subscription. Please try again in a few minutes or contact our support team at support@speedify.com';
    const context = String(response.message);

    msg.value = { severity, content, context };
    await userStore.refresh(); // Perhaps it succeeded on the backend but we failed to receive the success
    return;
  }

  const severity = 'success';
  const content = () => 'Your subscription has successfully been reactivated. Thank you :)';
  msg.value = { severity, content };

  // update state
  subscriptions.find(s => s.referenceId === referenceId)!.cancelable = 1;
};

const onRedeemCoupon = async (result: 'success' | 'failure', coupon?: any, message?: string | Error) => {
  if (result === 'success') {
    const discount = coupon.discount.percent;
    const severity = 'success';
    const name = humanReadableProductName(subscription.value.productName);
    const term = hyphenate(capitalizeFirstChar(subscriptionPeriod(subscription.value.productName)));
    const content = () =>
      `Success! A permanent discount of ${discount}% off has been applied to your` +
      ` ${name} ${term}. This change will be reflected on your next bill ` +
      `on ${renewalDate.value.toLocaleDateString()}.`;
    msg.value = { severity, content };
    await userStore.refresh();
    return;
  }

  if (result === 'failure') {
    const severity = 'error';
    const content = () =>
      'There was an issue cancelling your subscription. Please try again in a few minutes or contact our support team at support@speedify.com';
    const context = String(message || '');
    msg.value = { severity, content, context };
    await userStore.refresh();
  }
};

const ManageTeam = h(RouterLink, { to: { name: 'SubscriptionPortal', query: { referenceId } } }, () => 'Manage Team');

const onRemoveInactiveSeats = async (result: 'success' | 'failure', seats?: any, message?: string | Error) => {
  if (result === 'success') {
    const severity = 'success';
    const date = renewalDate.value.toLocaleDateString();
    subscription.value.referenceId;
    const content = () =>
      h('span', [
        `Your Speedify Team has been reduced from ${seats.total} seats` +
          ` to ${seats.active} seats. This change will be reflected on your next bill` +
          ` on ${date}. `,
        ManageTeam,
      ]);
    msg.value = { severity, content };
    userStore.subscriptions.find(s => s.referenceId === referenceId)!.quantity = seats.active;
    return;
  }

  if (result === 'failure') {
    const severity = 'error';
    const content = () =>
      'There was an issue removing inactive seats. Please try again in a few minutes or contact our support team at support@speedify.com';
    const context = String(message || '');
    msg.value = { severity, content, context };
    await userStore.refresh(true);
  }
};

const expiresOn = () => {
  const { endDate, nextPeriodDate } = subscription.value;
  const date = new Date(endDate ?? 0).getTime() === 0 ? nextPeriodDate : endDate;
  return new Date(date!).toLocaleDateString();
}
</script>
