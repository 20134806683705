import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import DialogService from 'primevue/dialogservice';
import Tooltip from 'primevue/tooltip';
import * as Sentry from '@sentry/vue';
import { config } from '@/config';
import Aura from '@primevue/themes/aura';
import { definePreset, $dt } from '@primevue/themes';

import App from './App.vue';
import { router } from './router';
import BannerService from '@/components/bannerservice';
import '@/assets/styles.scss';
import { useTheme } from '@/layout/composables/layout';
import { brand } from '@/util';

console.debug('Loading the Vue app ...');

if (import.meta.env.DEV) (window as any).$dt = $dt;

const preset = definePreset(Aura, {
  semantic: {
    primary: {
      50: '{sky.50}',
      100: '{sky.100}',
      200: '{sky.200}',
      300: '{sky.300}',
      400: '{sky.400}',
      500: '{sky.500}',
      600: '{sky.600}',
      700: '{sky.700}',
      800: '{sky.800}',
      900: '{sky.900}',
      950: '{sky.950}'
    },
    colorScheme: {
      dark: {
        primary: {
          color: '#00adee',
        }
      },
    }
  },
});
const theme = {
  preset,
  options: {
    prefix: 'p',
    darkModeSelector: '.dark',  // <html data-bs-theme="dark" /> for bootstrap
    cssLayer: {
      name: 'primevue',
      order: 'bootstrap, primevue, app',
    }
  }
};
useTheme().use(brand);

const app = createApp(App)
  .use(DialogService)
  .use(ToastService)
  .use(BannerService)
  .use(PrimeVue, { theme })
  .directive('tooltip', Tooltip)
  .use(createPinia().use(piniaPluginPersistedstate))

if (config.sentry.enabled) {
  Sentry.init({
    app,
    dsn: 'https://7126d12f24d49c8f3e2ce392ec5625ae@sentry2.speedifynetworks.com/2',
    tunnel: '/sentry',
    // replaysSessionSampleRate: 1.0,
    // replaysOnErrorSampleRate: 1.0,
    tracePropagationTargets: ['*'],
    integrations: [
      Sentry.browserTracingIntegration({ router }),
    ],
    normalizeDepth: 10,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: config.sentry.env,
    release: import.meta.env.SENTRY_RELEASE,
  });

  // TODO: upgrade our self-hosted sentry to support replays
  // This setup captures http req & resp body
  // eslint-disable-next-line no-constant-binary-expression, no-constant-condition
  if (false && ['development', 'staging'].includes(import.meta.env.MODE)) {
    Sentry.addIntegration(
      Sentry.replayIntegration({
        networkDetailDenyUrls: [`${window.location.origin}/api`],
      }),
    );
  }
}

app.use(router);
app.mount('#app');
console.debug('Loaded the Vue app');
console.debug(`LOADED ${import.meta.env.MODE}`);
console.debug(`SENTRY_RELEASE=${import.meta.env.SENTRY_RELEASE}`);
