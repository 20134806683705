<template>
  <nav class="navbar navbar-expand-lg navbar-light py-3">
    <div class="container">
      <router-link class="navbar-brand logo-container" :to="{ name: 'AccountDashboard' }">
        <SpeedifyLogo v-if="theme.curr.value === 'light'" />
        <img v-if="theme.curr.value === 'miri'" src="@/assets/images/miri-speedify-logo.svg" />
      </router-link>
      <div v-if="show" class="d-flex">
        <router-link
          v-if="showReportBtn"
          class="btn btn-ghost-dark"
          style="color: var(--on-surface)"
          :to="{ name: 'Reports' }"
        >
          REPORTS
          <img class="ms-1" src="/images/BETA_TAG.svg" />
        </router-link>
        <a
          type="button"
          class="btn btn-ghost-dark me-2"
          style="color: var(--on-surface)"
          aria-current="page"
          @click="logout"
        >
          LOG OUT
        </a>
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import { computed, onBeforeMount, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import wretch from 'wretch';
import SpeedifyLogo from './SpeedifyLogo.vue';
import { isAuthenticated } from '@/services/backend';
import { useUserStore } from '@/store/user';
import { useTheme } from '@/layout/composables/layout';
import { config } from '@/config';

const userStore = useUserStore();
const router = useRouter();

const { showLogout = true } = defineProps<{ showLogout?: boolean }>();
const route = useRoute();
const show = ref(showLogout || route.query.fromapp === '1');

onBeforeMount(async () => {
  if (showLogout === undefined && await isAuthenticated()) show.value = false;
});
const showReportBtn = computed(
  () =>
    config.subsmgmt.enableReportsPage &&
    route.name !== 'Reports' &&
    userStore.subscriptions.some(sub => sub.type === 'teams'),
);

const theme = useTheme();
(window as any).theme = theme;

const logout = () =>
// `userStore.reset` is not enough if the user manually vists /logout
// which invalidates cookies but keeps userStore in the browser memory
// userStore will be wrong if a different user is logged in.
// So, we have additional checks in `hasValidUser`
  wretch('/logout')
    .get()
    .res()
    .catch(console.error)
    .then(async () => {
      await router.replace('/login');
      userStore.reset();
    });
</script>
