import { ref } from 'vue';

type Theme = 'light' | 'dark' | 'miri';

const curr = ref<Theme>('light');

export const useTheme = () => {
  const use = (theme: Theme) => {
    const map = {
      light: ['dark', 'miri'],
      dark: ['light', 'miri'],
      miri: ['light', 'dark'],
    };
    // primevue, in house light, and miri
    document.documentElement.classList.remove(...map[theme]);
    document.documentElement.classList.add(theme);

    // bootstrap
    const mode = theme === 'dark' ? 'dark' : 'light';
    document.documentElement.setAttribute('data-bs-theme', mode);

    curr.value = theme;
  };

  return { use, curr };
};
