export const identity = <T>(x: T) => x;
export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
export const brand = ({
  'miri.speedify-develop.net': 'miri',
  'miri-staging.speedify.com': 'miri',
  'miri.speedify.com': 'miri',
}[window.location.hostname] ?? 'light') as 'miri' | 'light';

export function humanFileSize(bytes: number) {
  const lt0 = bytes < 0;
  let i = bytes == 0 ? 0 : Math.floor(Math.log(Math.abs(bytes)) / Math.log(1024));
  i = Math.max(i, 0);
  const str = +((Math.abs(bytes) / Math.pow(1024, i)).toFixed(2)) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB'][i];
  return lt0 ? '-' + str : str;
}

const Nonexistent = Symbol('Nonexistent');

/// sessionStorage based
class LRUCache<K, V> {
  private max: number;
  private orderedset: Set<K>; // keeps insertion order; amortized O(1)
  private readonly prefix = '__cache.'; // as a namespace

  constructor(max = 3) {
    this.max = max;
    this.orderedset = new Set();

    const observer = new PerformanceObserver(list =>
      list
        .getEntries()
        .filter(entry => entry instanceof PerformanceNavigationTiming && entry.type === 'reload')
        .forEach(() => this.clear()),
    );

    observer.observe({ type: "navigation", buffered: true });
  }

  clear() {
    this.orderedset.forEach(key => window.sessionStorage.removeItem(this.hash(key)));
    this.orderedset.clear();
    // Force clear cache on reload because orderedset is recreated
    const keys = [...Array(window.sessionStorage.length).keys()]
      .map(x => window.sessionStorage.key(x)!)
      .filter(x => x.startsWith(this.prefix));
    keys.forEach(k => window.sessionStorage.removeItem(k));
  }

  hash(key: K): string {
    return this.prefix + JSON.stringify(key);
  }

  get(key: K): V | typeof Nonexistent {
    const item = window.sessionStorage.getItem(this.hash(key))

    if (item !== null) {
      this.orderedset.delete(key);
      this.orderedset.add(key);
    }

    return item === null ? Nonexistent : JSON.parse(item);
  }

  set(key: K, value: Exclude<V, typeof Nonexistent>) {
    if (this.orderedset.has(key)) {
      this.orderedset.delete(key);
      window.sessionStorage.removeItem(this.hash(key));
    } else {
      while (this.orderedset.size >= this.max) {
        const first = this.orderedset.values().next().value!;
        this.orderedset.delete(first);
        window.sessionStorage.removeItem(this.hash(first));
      }
    }

    // XXX: subject to contention
    this.orderedset.add(key);
    window.sessionStorage.setItem(this.hash(key), JSON.stringify(value));
    return value;
  }
}

export const LRU = { Cache: LRUCache, Nonexistent }

export const expose = (o: { [k: string]: any }) =>
  Object.entries(o).forEach(([k, v]) => ((window as any)[k] = v));

export const dbg = <T>(x: T, loglevel: 'debug' | 'info' | 'warn' | 'error' | 'dir' = 'debug'): T => {
  console[loglevel](x);
  return x;
};
