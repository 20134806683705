<template>
  <Banner />
  <OrderCompleteBanner />
  <RouterActivationFlow />
  <!-- eslint-disable vuejs-accessibility/form-control-has-label, vuejs-accessibility/anchor-has-content, vuejs-accessibility/label-has-for -->
  <div class="container mt-5">
    <div class="row">
      <div class="col-md-12 col-lg-7">

        <div class="d-flex justify-content-between">
          <h2 class="mb-0">Licenses</h2>
          <div>
            <InputGroup class="px-1" style="line-height: 1">
              <InputText
                id="redeem-code"
                v-model="state.redemption.code"
                v-tooltip.top="'Enter an activation code to activate a router'"
                :invalid="!state.redemption.valid"
                placeholder="Activation Code"
                type="text"
                size="small"
                v-on:input="state.redemption.valid = true"
              />
              <Button label="Redeem" @click="redeem" />
            </InputGroup>
          </div>
          <div v-if="subscriptions.length > 3">
            <input
              id="subscription-search"
              v-model="state.subscriptionSearchString"
              class="form-control"
              type="text"
              name="subscription-search"
              placeholder="Filter subscriptions"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="You may search for a product, a type, or an email of a subscription's owner"
            />
          </div>
        </div>

        <SpecialDealCard v-if="theme.curr.value !== 'miri' && userStore.blackfriday24EligibleUpgrades" class="mt-2" />

        <div v-if="userStore.activeDevices.length >= userStore.availableDeviceLimit" class="alert alert-danger mt-2">
          <h3 v-if="userStore.activeDevices.length > userStore.availableDeviceLimit">Device Limit Exceeded</h3>
          <h3 v-if="userStore.activeDevices.length === userStore.availableDeviceLimit">Account Device Limit Reached</h3>

          <br />

          Your account can only be signed in on up to
          {{ !userStore.availableDeviceLimit ? 5 : userStore.availableDeviceLimit }}
          devices per week. To manage which devices are able to use speedify, please check your devices in the Sessions table on
          this page and remove some devices that may be erroneously active.

          <br />
          <br />

          If you are in need of more active devices, then please consider purchasing or upgrading to a Families or Teams
          subscription.
        </div>
        <div v-if="subscriptions.length > 0" id="subscriptions-data">
          <div v-for="subscription in filterSubscription" id="subscriptions-list" :key="subscription.referenceId" class="mt-3">
            <ProductCard :subscription="subscription" :user="user" />
          </div>
        </div>
        <div v-else>
          <div id="subscriptions-list" class="mt-3">
            <FreeProductCard :usedDataGB="usedDataGB" :freeLicenseDataGB="freeLicenseDataGB" />
          </div>
        </div>
        <AvailableAddOns v-if="userStore.recurlyAccountCode" />
        <div
          v-if="config.subsmgmt.enableLinkedDevicesView && (user.linkedDevices.length > 0 || user.activeDevices.length > 0)"
          id="linked-devices-data"
          class="mt-5"
        >
          <h2>Sessions ({{ user.activeDevices.length }}/{{ userStore.availableDeviceLimit }})</h2>
          <small>You can sign in and use this account on up to {{ userStore.availableDeviceLimit }} devices per week</small>
          <!-- TODO: use <Skeleton /> on pending table data -->
          <DeviceSessionTable
            class="mt-2"
            :activeDevices="user.activeDevices"
            :linkedDevices="user.linkedDevices"
            @handleShowModal="handleShowModal"
          />
        </div>
      </div>
      <div class="col-sm">
        <div>
          <h2>Your Account</h2>
        </div>
        <div>
          <div class="card my-4">
            <div class="card-body">
              <div class="card-title mt-2">
                <small>Account Email</small>
              </div>
              <h5 class="text-break">{{ user.email }}</h5>
              <hr />
              <!-- Change password modal trigger -->
              <div class="d-flex flex-row justify-content-around">
                <!-- TODO: remove in-house password change code after migration -->
                <a style="text-decoration: none">
                  <button
                    v-if="isFromZitadel"
                    type="button"
                    class="btn btn-speedify-blue stm-btn-change-password-attempt"
                    @click="tryChangePassword()"
                  >
                    Manage Account Security
                  </button>
                  <button
                    v-else
                    type="button"
                    class="btn btn-speedify-blue stm-btn-change-password-attempt"
                    @click="handleShowModal('changePasswordModal')"
                  >
                    Change Password
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <h2 class="mt-3">Links</h2>

        <!-- miri -->
        <div v-if="theme.curr.value === 'miri'" id="miri-product-support" class="card my-4">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 d-flex mb-3 flex-column">
                <h4 class="my-3">Miri Product Support</h4>
                <small>Get help and support with your Miri devices:</small>
              </div>
            </div>
            <ul class="mb-3" style="font-weight: 200">
              <li>
                <a href="https://support.miri.tech/docs/x510/" target="_blank" rel="noopener noreferrer">Knowledge Base</a>
              </li>
              <li>
                <a href="https://www.miri.tech/registration" target="_blank" rel="noopener noreferrer">Product Registration</a>
              </li>
              <li>
                <a href="https://support.miri.tech/docs/x510-firmware/" target="_blank" rel="noopener noreferrer">
                  Downloads/Firmware
                </a>
              </li>
              <li>
                <a href="https://support.miri.tech/support-ticket/" target="_blank" rel="noopener noreferrer">
                  Support Tickets
                </a>
              </li>
              <li>
                <a href="https://forum.miri.tech/" target="_blank" rel="noopener noreferrer">User Forums</a>
              </li>
            </ul>
            <div class="row">
              <div class="col-sm-12 d-flex mb-3 flex-column">
                <small>Want to Purchase Additional Routers?</small>
              </div>
            </div>
            <ul style="font-weight: 200">
              <li>
                <a
                  href="https://shop.miri.tech/products/miri-x510-cellular-bonded-router"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  US & Canada
                </a>
              </li>
              <li>
                <a href="https://www.miri.tech/buy" target="_blank" rel="noopener noreferrer">International</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="card my-4">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 d-flex justify-content-center mb-3">
                <h4 class="my-3">Download Speedify</h4>
              </div>
            </div>
            <div class="mb-3">
              <a href="https://speedify.com/download/" target="_blank" rel="noopener noreferrer">
                <img
                  src="https://speedify.com/wp-content/uploads/speedify-platforms-02-1.png"
                  class="card-img download-platforms"
                  alt="Download Speedify"
                />
              </a>
            </div>
          </div>
        </div>

        <div v-if="theme.curr.value === 'light'" id="live-stream-card" class="card my-4">
          <div class="card-body">
            <div>
              <a href="https://speedify.com/events/" target="_blank" rel="noopener noreferrer">
                <img
                  src="https://speedify.com/wp-content/uploads/Office-Hours-subsmgmt-tr.png"
                  class="card-img"
                  alt="Speedify LIVE"
                />
              </a>
            </div>
            <div class="my-2">
              <p>
                Every week, chat LIVE with Speedify CEO Alex Gizis and our developers about the latest news and updates with
                Speedify, and hit them with your hardest Speedify and networking tech questions - only on Speedify LIVE Office
                Hours.
              </p>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <a
                  href="https://speedify.com/events/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="btn btn-speedify-blue stm-btn-speedify-live-ad-click"
                >
                  Watch us LIVE
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <!-- change password modal -->
  <!-- TODO: remove this modal once we compltely migrated to zitadel -->
  <div
    v-if="state.showChangePasswordModal"
    id="changePasswordModal"
    class="modal fade"
    data-bs-backdrop="static"
    tabindex="-1"
    aria-labelledby="changePasswordModal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="changePasswordModal" class="modal-title">Change Password</h5>
          <button
            type="button"
            class="btn-close stm-btn-change-password-cancel"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="dismissChangePasswordModal"
          />
        </div>
        <form @submit.prevent="changePassword">
          <div class="modal-body">
            <!-- Current password input -->
            <div class="form-floating">
              <input
                id="currentPasswordInput"
                v-model="state.changePassword.currentPassword"
                type="password"
                class="form-control"
                placeholder="Current Password"
                aria-label="Current Password"
                aria-describedby="currentPassword"
                @change="changePasswordOnChangeHandler"
                @click="registerClick('currentPassword')"
              />
              <label for="currentPasswordInput">Current Password</label>
            </div>
            <small
              v-if="
                !v$.state.changePassword.currentPasswordClicked.mustBeTrue.$invalid &&
                v$.state.changePassword.currentPassword.required.$invalid
              "
              style="color: red"
            >
              Password is required.
            </small>

            <!-- new password input -->
            <div class="form-floating mt-3">
              <input
                id="newPasswordInput"
                v-model="state.changePassword.newPassword"
                type="password"
                class="form-control"
                placeholder="New Password"
                aria-label="New Password"
                aria-describedby="newPassword"
                @change="changePasswordOnChangeHandler"
                @click="registerClick('newPassword')"
              />
              <label for="newPasswordInput">New Password</label>
            </div>
            <small
              v-if="
                !v$.state.changePassword.newPasswordClicked.mustBeTrue.$invalid &&
                v$.state.changePassword.newPassword.required.$invalid
              "
              style="color: red"
            >
              Password is required.
            </small>
            <small v-if="v$.state.changePassword.newPassword.minLength.$invalid" style="color: red">
              Password must have at least 8 characters.
            </small>

            <!-- repeat new password input -->
            <div class="form-floating mt-3">
              <input
                id="repeatPasswordInput"
                v-model="state.changePassword.repeatNewPassword"
                type="password"
                class="form-control"
                placeholder="Repeat New Password"
                aria-label="Repeat New Password"
                aria-describedby="repeatNewPassword"
                @change="changePasswordOnChangeHandler"
                @click="registerClick('repeatNewPassword')"
              />
              <label for="repeatPasswordInput">Repeat New Password</label>
            </div>
            <small v-if="v$.state.changePassword.repeatNewPassword.sameAsNewPassword.$invalid" style="color: red">
              Passwords must be identical.
            </small>

            <!-- alerts for change password modal -->
            <div
              v-if="state.changePassword.success"
              class="alert alert-success mt-3 stm-msg-change-password-success"
              role="alert"
            >
              <i class="fas fa-check-circle"></i>
              &nbsp;Password has been successfully changed!
            </div>
            <div v-if="state.changePassword.failed" class="alert alert-danger mt-3 stm-msg-change-password-error" role="alert">
              <i class="fas fa-exclamation-circle"></i>
              &nbsp;Error: Unable to update password
              <hr />
              {{ state.changePassword.failureError }}
            </div>
          </div>

          <div class="modal-footer">
            <button
              v-if="state.changePassword.success"
              type="button"
              class="btn btn-speedify-blue stm-btn-change-password-done"
              data-bs-dismiss="modal"
              @click="dismissChangePasswordModal"
            >
              Done
            </button>
            <button
              v-if="!state.changePassword.success"
              type="button"
              class="btn btn-secondary stm-btn-change-password-cancel"
              data-bs-dismiss="modal"
              @click="dismissChangePasswordModal"
            >
              Cancel
            </button>
            <button
              v-if="!state.changePassword.success"
              type="submit"
              class="btn btn-speedify-blue stm-btn-change-password-confirm"
              :class="{
                disabled:
                  v$.state.changePassword.currentPassword.required.$invalid ||
                  v$.state.changePassword.newPassword.required.$invalid ||
                  v$.state.changePassword.newPassword.minLength.$invalid ||
                  v$.state.changePassword.repeatNewPassword.sameAsNewPassword.$invalid,
              }"
            >
              Change Password
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- Rename linked devices modal -->
  <RenameDeviceModal
    v-if="state.showRenameDeviceModal"
    :uuid="state.deviceUUID"
    :nickname="state.currentNickname"
    @getLinkedDevices="refreshLinkedDevices"
    @dismissModal="dismissModal"
  />

  <!-- Revoke Active Device Modal -->
  <RevokeActiveDeviceModal
    v-if="state.showRevokeActiveDeviceModal"
    :uuid="state.deviceUUID"
    :nickname="state.currentNickname"
    @dismissModal="dismissModal"
  />

  <!-- Revoke Linked Device Modal -->
  <RevokeDeviceModal
    v-if="state.showRevokeDeviceModal"
    :uuid="state.deviceUUID"
    :nickname="state.currentNickname"
    @getLinkedDevices="refreshLinkedDevices"
    @dismissModal="dismissModal"
  />
</template>

<script lang="ts" setup>
import { reactive, computed, h, ref, onMounted } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, minLength, sameAs } from '@vuelidate/validators';
import { storeToRefs } from 'pinia';

import Button from 'primevue/button';
import InputGroup from 'primevue/inputgroup';
import InputText from 'primevue/inputtext';

import Banner from '@/components/Banner.vue';
import OrderCompleteBanner from '@/components/OrderCompleteBanner.vue';
import DeviceSessionTable from '@/components/DeviceSessionsTable.vue';
import RenameDeviceModal from '@/components/RenameDeviceModal.vue';
import RevokeDeviceModal from '@/components/RevokeDeviceModal.vue';
import RevokeActiveDeviceModal from '@/components/RevokeActiveDeviceModal.vue';
import ProductCard from '@/components/ProductCard.vue';
import AvailableAddOns from '@/components/AvailableAddOns.vue';
import { useUserStore } from '@/store/user';
import { useLinkedDevicesStore } from '@/store/linkedDevices';
import { config } from '@/config';
import { changeUserPassword, getToken, redeemRouterCoupon } from '@/services/backend';
import { showModal } from '@/services/modal';
import FreeProductCard from '@/components/FreeProductCard.vue';
import SpecialDealCard from '@/components/SpecialDealCard.vue';
import RouterActivationFlow from '@/components/RouterActivationFlow.vue';
import DialogRouterActivated from '@/components/DialogRouterActivated.vue';
import { useDialog } from 'primevue/usedialog';
import { useTheme } from '@/layout/composables/layout';

const theme = useTheme();
const dialog = useDialog();
const userStore = useUserStore();
const state = reactive({
  subscriptionSearchString: '',
  showChangePasswordModal: false,
  changePassword: {
    currentPassword: '',
    newPassword: '',
    repeatNewPassword: '',
    currentPasswordClicked: false,
    newPasswordClicked: false,
    repeatNewPasswordClicked: false,
    success: false,
    failed: false,
    failureError: '' as string | Error,
  },
  showRenameModal: false,
  showRenameDeviceModal: false,
  showRevokeDeviceModal: false,
  showRevokeActiveDeviceModal: false,
  deviceUUID: '',
  currentNickname: '',
  redemption: {
    code: '',
    valid: true,
  },
});

const { userid, email, activeDevices, subscriptions, freeLicenseDataGB, usedDataGB } = storeToRefs(userStore);

const linkedDevicesStore = useLinkedDevicesStore();
const { devices: linkedDevices } = storeToRefs(linkedDevicesStore);

const user = computed(() => ({
  userid: userid.value,
  email: email.value,
  activeDevices: activeDevices.value,
  linkedDevices: linkedDevices.value,
}));

// FIXME: https://github.com/Connectify/speedify-subs-mgmt-v2/issues/767
const handleShowModal = (elementID: string, deviceUUID: string = '', friendlyName: string = '') => {
  // @ts-ignore
  state[`show${elementID.charAt(0).toUpperCase()}${elementID.slice(1)}`] = true;
  state.deviceUUID = deviceUUID;
  state.currentNickname = friendlyName;
  showModal(elementID);
};

const filterSubscription = computed(() => {
  if (state.subscriptionSearchString === '') {
    return subscriptions.value;
  }
  return subscriptions.value.filter(
    s =>
      s.productName?.match(state.subscriptionSearchString) ||
      s.type?.match(state.subscriptionSearchString) ||
      s.ownerEmail?.toLowerCase().match(state.subscriptionSearchString.replace('+', '\\+').toLowerCase()),
  );
});

const changePasswordOnChangeHandler = () => {
  state.changePassword.success = false;
  state.changePassword.failed = false;
  state.changePassword.failureError = '';
};

const dismissChangePasswordModal = () => {
  state.showChangePasswordModal = false;
  state.changePassword.currentPassword = '';
  state.changePassword.newPassword = '';
  state.changePassword.repeatNewPassword = '';
  state.changePassword.success = false;
  state.changePassword.failed = false;
  state.changePassword.failureError = '';
  state.changePassword.currentPasswordClicked = false;
  state.changePassword.newPasswordClicked = false;
  state.changePassword.repeatNewPasswordClicked = false;
};

const isFromZitadel = ref(false);

onMounted(async () => {
  const payload = await getToken();
  await linkedDevicesStore.refresh();

  isFromZitadel.value = Boolean(payload?.idp && payload.idp === import.meta.env.VITE_ZITADEL_ISSUER);
});

const tryChangePassword = async () => {
  const payload = await getToken();
  // XXX: zitadel requires login
  const url = `${payload!.idp}/ui/console/users/me?id=security`;
  return window.open(url, '_blank')?.focus();
};

const changePassword = async () => {
  const response = await changeUserPassword(state.changePassword.currentPassword, state.changePassword.newPassword);

  if (response.error) {
    state.changePassword.success = false;
    state.changePassword.failed = true;
    state.changePassword.failureError = response.message;
  } else {
    state.changePassword.success = true;
    state.changePassword.failed = false;
    state.changePassword.failureError = '';
  }
};

const refreshLinkedDevices = () => linkedDevicesStore.refresh();

const dismissModal = () => {
  state.showRenameDeviceModal = false;
  state.showRevokeDeviceModal = false;
  state.showRevokeActiveDeviceModal = false;
  state.deviceUUID = '';
  state.currentNickname = '';
};

const mustBeTrue = (value: any) => value === true;

const rules = computed(() => ({
  state: {
    changePassword: {
      currentPassword: {
        required,
      },
      newPassword: {
        required,
        minLength: minLength(8),
      },
      repeatNewPassword: {
        sameAsNewPassword: sameAs(state.changePassword.newPassword),
      },
      currentPasswordClicked: {
        mustBeTrue,
      },
      newPasswordClicked: {
        mustBeTrue,
      },
      repeatNewPasswordClicked: {
        mustBeTrue,
      },
    },
    renameDevice: {
      nickname: {
        required,
      },
    },
  },
}));

/*
  We don't want vuelidate to complain to the user immediately,
  so let's wait until the user clicked an input to display issues
*/
const registerClick = (element: string) => {
  // @ts-ignore
  state.changePassword[`${element}Clicked`] = true;
};

const v$ = useVuelidate(rules, { state });

const redeem = async () => {
  const result = await redeemRouterCoupon(state.redemption.code);
  const props = { modal: true, draggable: false, header: 'Success!' };

  if (result.error) {
    state.redemption.valid = false;
    dialog.open(h('div', null), {
      props: {
        ...props,
        header: 'Invalid code',
        style: 'max-width: 14em; color: red',
        contentStyle: 'display: none',
        pt: { header: { style: 'border-bottom: none' } },
      },
    });
  } else {
    dialog.open(DialogRouterActivated, { props });
  }
};
</script>

<style type="text/css" media="screen">
div#redeem .v-field__outline__start {
  border-top-left-radius: var(--button-border-radius) !important;
  border-bottom-left-radius: var(--button-border-radius) !important;
}
div#redeem .v-field__outline__end {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

#miri-product-support li::marker {
  color: var(--href-color);
}

@media screen and (max-width:767px) {
  #subscription-search {
    display: none;
  }

  #subscriptions-list a.btn {
    font-size: 0.8rem;
  }
}
</style>
