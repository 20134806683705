<template><div></div></template>

<script lang="ts" setup>
import { provide, onMounted, ref, markRaw } from 'vue';
import { useRoute } from 'vue-router';
import { useDialog } from 'primevue/usedialog';
import * as Sentry from '@sentry/vue'

import DialogRouterPlansPricing from '@/components/DialogRouterPlansPricing.vue';
import RouterPlansPricingHeader from './RouterPlansPricingHeader.vue';
import RouterPartner90DayActivated from '@/components/RouterPartner90DayActivated.vue';
import BannerRouterPoweredBySpeedifyActivated from '@/components/banners/BannerRouterPoweredBySpeedifyActivated.vue';
import BannerRouterLicenseRequired from '@/components/banners/BannerRouterLicenseRequired.vue';

import { useUserStore } from '@/store/user';
import { useLinkedDevicesStore } from '@/store/linkedDevices';
import { useDialogStore } from '@/store/dialog';
import { modal } from '@/symbol';
import { useBanner } from '@/components/usebanner';
import { setShown90DaySuccess, setSeenActivationSuccess, getRouterDetails } from '@/services/backend';
import { sleep } from '@/util';
import { config } from '@/config';
import { storeToRefs } from 'pinia';
import DialogRouterActivated from './DialogRouterActivated.vue';
import type { Subscription } from '@/model';

const route = useRoute();
const userStore = useUserStore();
const dialogStore = useDialogStore();
const linkedDevicesStore = useLinkedDevicesStore();
const currModal = ref<string | null>(null);
const data = ref<any>({});
const showModal = (name: string | null) => (currModal.value = name);
provide(modal, { currModal, data, showModal });

const dialog = useDialog();
const banner = useBanner();

interface RouterDetails {
  uuid: string;
  userid: number;
  vendor: string;
  serialNumber: string;
  created_at: string; // Date
  has_shown_90day_success: boolean;
  seen_activation_success: boolean;
}

const handleRouterActivationSuccess = (deviceUUID: string, router: RouterDetails, subscription?: Subscription) => {
  if (config.partnerRouterVendors.includes(router.vendor)) {
    if (userStore.routerQty >= linkedDevicesStore.routers.length) {
      if (router.seen_activation_success) return;

      dialog.open(DialogRouterActivated, {
        props: {
          modal: true,
          draggable: false,
          header: 'Success!',
        },
        onClose: () => void setSeenActivationSuccess(deviceUUID).catch(err => Sentry.captureException(err, { extra: { route } })),
      });
      banner.open(BannerRouterPoweredBySpeedifyActivated, { props: router });
    } else if (userStore.routerQty < linkedDevicesStore.routers.length) {
      if (router.has_shown_90day_success) return;

      dialog.open(RouterPartner90DayActivated, {
        props: {
          modal: true,
          draggable: false,
          header: 'Success!',
        },
        onClose: () => void setShown90DaySuccess(deviceUUID).catch(err => Sentry.captureException(err, { extra: { route } })),
      });
      banner.open(BannerRouterPoweredBySpeedifyActivated, { props: router });
    }
  } else { // non-partner router
    if (userStore.routerQty >= linkedDevicesStore.routers.length) {
      if (router.seen_activation_success) return;

      dialog.open(DialogRouterActivated, {
        props: {
          modal: true,
          draggable: false,
          header: 'Success!',
        },
        onClose: () => void setSeenActivationSuccess(deviceUUID).catch(err => Sentry.captureException(err, { extra: { route } })),
      });
      banner.open(BannerRouterPoweredBySpeedifyActivated, { props: router });
      return;
    }

    if (dialogStore.showRouterPlansPricing) {
      dialog.open(DialogRouterPlansPricing, {
        props: {
          modal: true,
          draggable: false,
        },
        templates: { header: markRaw(RouterPlansPricingHeader) },
        onClose: () => (dialogStore.showRouterPlansPricing = false),
      });
    }
    banner.open(BannerRouterLicenseRequired, { props: { subscription } });
  }
};

const dispatch = async () => {
  await userStore.refresh();
  await linkedDevicesStore.refresh();

  const { subscriptions } = storeToRefs(userStore);
  const subscription = subscriptions.value.find(s => s.productName.match(/router/i));

  if (dialogStore.deviceLoggedIn) {
    for (let i = 0, len = 10; i < len; i++) {
      const resp = await getRouterDetails(dialogStore.deviceLoggedIn)
        .notFound(() => sleep(1000))
        .json<RouterDetails>(resp => {
          i = 10;
          return resp;
        })
        .catch(err => { Sentry.captureException(err); return null });

      // TODO: persist role in localStorage
      resp && handleRouterActivationSuccess(dialogStore.deviceLoggedIn, resp, subscription);
    }

    dialogStore.deviceLoggedIn = false;
  }
};

onMounted(dispatch);
</script>
