<template>
  <Banner />
  <!-- modal for confirmation -->
  <div
    v-if="state.showConfirmUpgradeModal"
    id="confirmUpgradeModal"
    class="modal fade"
    data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="confirmUpgradeModalLabel" aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="confirmUpgradeModalLabel" class="modal-title">Upgrade Confirmation</h5>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            data-bs-dismiss="modal"
            @click="cancelUpgrade()"
          />
        </div>
        <div class="modal-body">
          <div class="card mb-3">
            <div class="card-body pb-3">
              <div class="card-title mt-3">
                <h5>{{ upgradeSelection.details.title }}</h5>
              </div>
              <p>
                <span v-if="upgradeSelection.planCode.match(/team/)">
                  ${{ upgradeSelection.upgradeMonthlyPrice() }} per month per user |
                </span>
                <span v-if="upgradeSelection?.details?.blackfriday24EligibleUpgrade && upgradeSelection?.details?.discountPrice">
                  <strike>${{ upgradeSelection.upgradePriceForQuantity() }}</strike>
                  ${{ upgradeSelection.discountedBlackfriday24UpgradePriceForQuantity() }} for the
                  first term, ${{ upgradeSelection.upgradePriceForQuantity() }} {{ upgradeSelection.subscriptionBillingCycle() }}
                </span>
                <span v-else>
                  ${{ upgradeSelection.upgradePriceForQuantity() }} {{ upgradeSelection.subscriptionBillingCycle() }}
                </span>
              </p>
            </div>
          </div>
          <div v-if="upgradeSelection.details.blackfriday24EligibleUpgrade" class="my-3">
            You will be charged a prorated fee based on your current plan and the discount for the
            current term. You will be
            then billed {{ toUSD(upgradeSelection.upgradePriceForQuantity(subscription.quantity)!) }}
            starting on {{ upgradeSelection.upgradesNextBillingPeriod() }}.
          </div>
          <div v-else class="my-3">
            You will be {{ upgradePriceDifference() < 0 ? 'refunded': 'billed' }} ${{ Math.abs(upgradePriceDifference()) }} now,
            then billed {{ toUSD(upgradeSelection.upgradePriceForQuantity(subscription.quantity)!) }}
            starting on {{ upgradeSelection.upgradesNextBillingPeriod() }}.
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="cancelUpgrade()">Cancel</button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="upgradeToSelectedSubscription()"
          >
            Confirm Upgrade
          </button>
        </div>
      </div>
    </div>
  </div>

  <div v-if="route.meta.productNotFound" class="container mt-5">
    <div class="alert alert-warning">
      The product referenced was not found. Please contact our support at support@speedify.com if
      you think this is an error
    </div>
    Please click <router-link to="/account">here</router-link> to go back to the dashboard
  </div>
  <div v-else class="container mt-5">
    <nav aria-label="breadcrumb my-3">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><router-link :to="{ name: 'AccountDashboard' }">Dashboard</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">Upgrade your Speedify subscription</li>
      </ol>
    </nav>

    <!-- fresh page load -->
    <div v-if="!state.upgrade.pending && !state.upgrade.success && !state.upgrade.error" id="notInProgress">
      <h1 class="mb-3">Upgrade your Speedify subscription</h1>
      <div class="row my-5">
        <div class="col-sm">
          <div class="card mb-3">
            <div class="card-body pb-4">
              <div class="card-title">
                <small>Current Plan</small>
              </div>
              <h5>
                {{ humanReadableProductName(subscription.productName) }}
                {{ hyphenate(capitalizeFirstChar(subscriptionPeriod(subscription.productName))) }}
              </h5>
            </div>
          </div>
        </div>
        <div v-if="subscription.cancelable === 1 && !subscription.planCode?.match(/nonrenew/)" class="col-sm mb-3">
          <div class="card">
            <div class="card-body pb-4">
              <div class="card-title">
                <small>Renews on</small>
              </div>
              <h5>{{ new Date(subscription.nextPeriodDate!).toDateString() }}</h5>
              </div>
          </div>
        </div>
      </div>

      <div class="row my-3">
        <h3 v-if="!subscription.blackfriday24Eligible">Available Upgrades</h3>
        <h3 v-else>Available Upgrades - <span style="color: #FF70BC">SALE!</span></h3>
        <small>Unlock additional functionality or lock in a lower rate</small>
      </div>

      <AppleStoreUpgradesInfo v-if="subscription.storeType === 'AS'" />
      <GooglePlayUpgradesInfo v-else-if="subscription.storeType === 'GP'" />
      <!-- FS?, FSC, REC -->
      <NonMobileStoreUpgradesInfo v-else :subscription="subscription" @showUpgradeConfirmationModal="handleShowModal" />
    </div>

    <!-- upgrade pending resolution -->
    <div v-else-if="state.upgrade.pending && !state.upgrade.success && !state.upgrade.error" id="whilePending">
      <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <hr/>

      Just a moment, we are upgrading your plan. Please don't refresh the page.
    </div>

    <!-- upgrade resolved -->
    <div
      v-else-if="!state.upgrade.pending && state.upgrade.success"
      class="alert alert-success stm-msg-upgrade-success"
      role="alert"
    >
      <i class="fas fa-check-circle"></i>

      Your subscription has been upgraded. Please allow a few minutes for the change to reflect on your account.
      You may need to refresh the page to see the changes.
    </div>

    <!-- upgrade rejected -->
    <div
      v-else-if="!state.upgrade.pending && state.upgrade.error"
      class="alert alert-danger stm-msg-upgrade-failure"
      role="alert"
    >
      <i class="fas fa-circle-xmark"></i>

      Something went wrong. Please try again later or contact support@speedify.com for help
      <hr />
      {{ state.upgrade.errorContext }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';

import { humanReadableProductName, subscriptionPeriod } from '@/services/subscription';
import { hyphenate, capitalizeFirstChar } from '@/services/string';
import { getToken, upgradeSubscription } from '@/services/backend';
import { showModal } from '@/services/modal';
import AppleStoreUpgradesInfo from '@/components/AppleStoreUpgradesInfo.vue';
import GooglePlayUpgradesInfo from '@/components/GooglePlayUpgradesInfo.vue';
import NonMobileStoreUpgradesInfo from '@/components/NonMobileStoreUpgradesInfo.vue';
import useUpgradeSelection from '@/store/upgradeSelection';
import { useUserStore } from '@/store/user';
import { useLinkedDevicesStore } from '@/store/linkedDevices';
import Banner from './Banner.vue';
import { useBanner } from './usebanner';
import BannerRouterLicenseRequired from './banners/BannerRouterLicenseRequired.vue';

const route = useRoute();
const banner = useBanner();

// route.meta.referenceId will carry the referenceId when browsing the
// upgrade shortcut link /account/upgrade
const referenceId = (route.query.referenceId || route.meta.referenceId) as string;
const upgradeSelection = useUpgradeSelection();
const userStore = useUserStore();
const linkedDevicesStore = useLinkedDevicesStore();
const { subscriptions } = storeToRefs(userStore);
const state = reactive({
  upgrade: {
    pending: false,
    success: false,
    error: false,
    errorContext: '' as string | Error,
  },
  showConfirmUpgradeModal: false,
});

const handleShowModal = (elementID: string) => {
  // @ts-ignore
  state[`show${elementID.charAt(0).toUpperCase()}${elementID.slice(1)}`] = true;
  showModal(elementID);
};

const cancelUpgrade = () => {
  state.showConfirmUpgradeModal = false;
  upgradeSelection.reset();
};

const subscription = computed(() => subscriptions.value.find((s) => s.referenceId === referenceId)!);

onMounted(async () => {
  const numRouterSubs = userStore.routerQty;
  const payload = await getToken();

  if (payload?.role === 'router' && numRouterSubs === 0) {
    banner.open(BannerRouterLicenseRequired, { props: { subscription } });
  } else if (payload?.role === 'router' && numRouterSubs < linkedDevicesStore.routers.length) {
    banner.open(BannerRouterLicenseRequired, { props: { subscription } });
  }
});

// XXX: Why isn't .format decalred a static method?
// eslint-disable-next-line @typescript-eslint/unbound-method
const toUSD = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format;

const getNewPlanDetails = (newPlanCode: string) => subscription.value.upgradeDetails!.upgradeOptions.find((o: any) => o.planCode === newPlanCode);

const getPlanQuantity = (newPlanDetails: any): number => {
  if (newPlanDetails.planCode.match(/teams/)) {
    if (newPlanDetails.quantity < subscription.value.quantity) {
      return subscription.value.quantity;
    }
  }
  return newPlanDetails.quantity;
};

const upgradePriceDifference = () => {
  let fullPrice;
  if (upgradeSelection?.details?.blackfriday24EligibleUpgrade) {
    fullPrice = upgradeSelection.discountedBlackfriday24UpgradePriceForQuantity(subscription.value.quantity);
  } else {
    fullPrice = upgradeSelection.upgradePriceForQuantity(subscription.value.quantity);
  }
  if (!fullPrice) {
    return 0;
  }
  let difference = -(Number(Number(subscription.value.upgradeDetails!.currentProduct.price.replace('$', '')).toFixed(2)));
  if (subscription.value.upgradeDetails!.currentProduct.title.toLowerCase().match(/teams/)) {
    difference *= subscription.value.quantity;
  }
  difference += fullPrice;
  return Number(difference.toFixed(2));
};

const upgradeToSelectedSubscription = async () => {
  state.showConfirmUpgradeModal = false;
  state.upgrade.pending = true;

  const quantity = getPlanQuantity(getNewPlanDetails(upgradeSelection.planCode));
  // TODO: refactor this call. this function should get user details within the service itself using
  // the store but there is currently a cyclic dep which needs to be addressed
  const result = await upgradeSubscription(
    referenceId,
    subscription.value.storeType,
    quantity,
    userStore.userid,
    userStore.email,
    upgradeSelection.details.blackfriday24EligibleUpgrade
  );
  if (result.error) {
    state.upgrade.error = true;
    state.upgrade.pending = false;
    state.upgrade.errorContext = result.message;
    return;
  }

  state.upgrade.success = true;
  state.upgrade.pending = false;
  if (subscription.value.blackfriday24Eligible) {
    userStore.delistBlackFriday24PromotionByReferenceId(subscription.value.referenceId);
  }
};
</script>
